/* TODO(sgomes): Figure out what to do about desktop font sizes. */
/* TODO(sgomes): Figure out what to do about i18n and i18n font sizes. */
@keyframes mdc-ripple-fg-radius-in {
  from {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1); }
  to {
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); } }

@keyframes mdc-ripple-fg-opacity-in {
  from {
    animation-timing-function: linear;
    opacity: 0; }
  to {
    opacity: var(--mdc-ripple-fg-opacity, 0.16); } }

@keyframes mdc-ripple-fg-opacity-out {
  from {
    animation-timing-function: linear;
    opacity: var(--mdc-ripple-fg-opacity, 0.16); }
  to {
    opacity: 0; } }

.mdc-ripple-surface--test-edge-var-bug {
  --mdc-ripple-surface-test-edge-var: 1px solid #000;
  visibility: hidden; }
  .mdc-ripple-surface--test-edge-var-bug::before {
    border: var(--mdc-ripple-surface-test-edge-var); }

/**
 * Creates a rule that will be applied when an MDC-Web component is within the context of an RTL layout.
 *
 * Usage Example:
 * ```scss
 * .mdc-foo {
 *   position: absolute;
 *   left: 0;
 *
 *   @include mdc-rtl {
 *     left: auto;
 *     right: 0;
 *   }
 *
 *   &__bar {
 *     margin-left: 4px;
 *     @include mdc-rtl(".mdc-foo") {
 *       margin-left: auto;
 *       margin-right: 4px;
 *     }
 *   }
 * }
 *
 * .mdc-foo--mod {
 *   padding-left: 4px;
 *
 *   @include mdc-rtl {
 *     padding-left: auto;
 *     padding-right: 4px;
 *   }
 * }
 * ```
 *
 * Note that this works by checking for [dir="rtl"] on an ancestor element. While this will work
 * in most cases, it will in some cases lead to false negatives, e.g.
 *
 * ```html
 * <html dir="rtl">
 *   <!-- ... -->
 *   <div dir="ltr">
 *     <div class="mdc-foo">Styled incorrectly as RTL!</div>
 *   </div>
 * </html>
 * ```
 *
 * In the future, selectors such as :dir (http://mdn.io/:dir) will help us mitigate this.
 */
/**
 * Takes a base box-model property - e.g. margin / border / padding - along with a default
 * direction and value, and emits rules which apply the value to the
 * "<base-property>-<default-direction>" property by default, but flips the direction
 * when within an RTL context.
 *
 * For example:
 *
 * ```scss
 * .mdc-foo {
 *   @include mdc-rtl-reflexive-box(margin, left, 8px);
 * }
 * ```
 * is equivalent to:
 *
 * ```scss
 * .mdc-foo {
 *   margin-left: 8px;
 *
 *   @include mdc-rtl {
 *     margin-right: 8px;
 *     margin-left: 0;
 *   }
 * }
 * ```
 * whereas:
 *
 * ```scss
 * .mdc-foo {
 *   @include mdc-rtl-reflexive-box(margin, right, 8px);
 * }
 * ```
 * is equivalent to:
 *
 * ```scss
 * .mdc-foo {
 *   margin-right: 8px;
 *
 *   @include mdc-rtl {
 *     margin-right: 0;
 *     margin-left: 8px;
 *   }
 * }
 * ```
 *
 * You can also pass a 4th optional $root-selector argument which will be forwarded to `mdc-rtl`,
 * e.g. `@include mdc-rtl-reflexive-box(margin, left, 8px, ".mdc-component")`.
 *
 * Note that this function will always zero out the original value in an RTL context. If you're
 * trying to flip the values, use mdc-rtl-reflexive-property().
 */
/**
 * Takes a base property and emits rules that assign <base-property>-left to <left-value> and
 * <base-property>-right to <right-value> in a LTR context, and vice versa in a RTL context.
 * For example:
 *
 * ```scss
 * .mdc-foo {
 *   @include mdc-rtl-reflexive-property(margin, auto, 12px);
 * }
 * ```
 * is equivalent to:
 *
 * ```scss
 * .mdc-foo {
 *   margin-left: auto;
 *   margin-right: 12px;
 *
 *   @include mdc-rtl {
 *     margin-left: 12px;
 *     margin-right: auto;
 *   }
 * }
 * ```
 *
 * A 4th optional $root-selector argument can be given, which will be passed to `mdc-rtl`.
 */
/**
 * Takes an argument specifying a horizontal position property (either "left" or "right") as well
 * as a value, and applies that value to the specified position in a LTR context, and flips it in a
 * RTL context. For example:
 *
 * ```scss
 * .mdc-foo {
 *   @include mdc-rtl-reflexive-position(left, 0);
 *   position: absolute;
 * }
 * ```
 * is equivalent to:
 *
 * ```scss
 *  .mdc-foo {
 *    position: absolute;
 *    left: 0;
 *    right: initial;
 *
 *    @include mdc-rtl {
 *      right: 0;
 *      left: initial;
 *    }
 *  }
 * ```
 * An optional third $root-selector argument may also be given, which is passed to `mdc-rtl`.
 */
.mdc-select {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.04em;
  text-decoration: inherit;
  text-transform: inherit;
  /* @alternate */
  color: rgba(0, 0, 0, 0.87);
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
  background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%230%22%20fill-rule%3D%22evenodd%22%20opacity%3D%220.54%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E);
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  height: 56px;
  border: none;
  border-radius: 4px 4px 0 0;
  outline: none;
  background-repeat: no-repeat;
  background-position: right 10px center;
  cursor: pointer;
  overflow: visible; }
  [dir="rtl"] .mdc-select, .mdc-select[dir="rtl"] {
    background-position: left 10px center; }
  .mdc-select--theme-dark .mdc-select,
  .mdc-theme--dark .mdc-select {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%23fff%22%20fill-rule%3D%22evenodd%22%20opacity%3D%220.54%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E);
    background-color: rgba(255, 255, 255, 0.1); }
  .mdc-select__menu {
    position: fixed;
    top: 0;
    left: 0;
    max-height: 100%;
    transform-origin: center center;
    z-index: 4; }
  .mdc-select__surface {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 1rem;
    line-height: 1.75rem;
    font-weight: 400;
    letter-spacing: 0.04em;
    text-decoration: inherit;
    text-transform: inherit;
    /* @alternate */
    color: rgba(0, 0, 0, 0.87);
    color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
    padding-left: 16px;
    padding-right: 26px;
    --mdc-ripple-fg-size: 0;
    --mdc-ripple-left: 0;
    --mdc-ripple-top: 0;
    --mdc-ripple-fg-scale: 1;
    --mdc-ripple-fg-translate-end: 0;
    --mdc-ripple-fg-translate-start: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    will-change: transform, opacity;
    display: flex;
    position: relative;
    flex-grow: 1;
    width: 100%;
    height: 56px;
    border: none;
    border-radius: 4px 4px 0 0;
    outline: none;
    background-color: rgba(0, 0, 0, 0.04);
    appearance: none;
    overflow: hidden; }
    [dir="rtl"] .mdc-select .mdc-select__surface,
    .mdc-select[dir="rtl"] .mdc-select__surface {
      padding-left: 26px;
      padding-right: 16px; }
    .mdc-select__surface::before, .mdc-select__surface::after {
      position: absolute;
      border-radius: 50%;
      opacity: 0;
      pointer-events: none;
      content: ""; }
    .mdc-select__surface::before {
      transition: opacity 15ms linear; }
    .mdc-select__surface.mdc-ripple-upgraded::after {
      top: 0;
      left: 0;
      transform: scale(0);
      transform-origin: center center; }
    .mdc-select__surface.mdc-ripple-upgraded--unbounded::after {
      top: var(--mdc-ripple-top, 0);
      left: var(--mdc-ripple-left, 0); }
    .mdc-select__surface.mdc-ripple-upgraded--foreground-activation::after {
      animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
    .mdc-select__surface.mdc-ripple-upgraded--foreground-deactivation::after {
      animation: 150ms mdc-ripple-fg-opacity-out;
      transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
    .mdc-select__surface::before, .mdc-select__surface::after {
      top: calc(50% - 100%);
      left: calc(50% - 100%);
      width: 200%;
      height: 200%; }
    .mdc-select__surface.mdc-ripple-upgraded::before {
      top: calc(50% - 100%);
      left: calc(50% - 100%);
      width: 200%;
      height: 200%;
      transform: scale(var(--mdc-ripple-fg-scale, 0)); }
    .mdc-select__surface.mdc-ripple-upgraded--unbounded::before {
      top: var(--mdc-ripple-top, calc(50% - 50%));
      left: var(--mdc-ripple-left, calc(50% - 50%));
      width: var(--mdc-ripple-fg-size, 100%);
      height: var(--mdc-ripple-fg-size, 100%);
      transform: scale(var(--mdc-ripple-fg-scale, 0)); }
    .mdc-select__surface.mdc-ripple-upgraded::after {
      width: var(--mdc-ripple-fg-size, 100%);
      height: var(--mdc-ripple-fg-size, 100%); }
    .mdc-select__surface::before, .mdc-select__surface::after {
      background-color: black; }
    .mdc-select__surface:hover::before {
      opacity: 0.04; }
    .mdc-select__surface:not(.mdc-ripple-upgraded):focus::before, .mdc-select__surface.mdc-ripple-upgraded--background-focused::before {
      transition-duration: 75ms;
      opacity: 0.12; }
    .mdc-select__surface:not(.mdc-ripple-upgraded)::after {
      transition: opacity 150ms linear; }
    .mdc-select__surface:not(.mdc-ripple-upgraded):active::after {
      transition-duration: 75ms;
      opacity: 0.16; }
    .mdc-select__surface.mdc-ripple-upgraded {
      --mdc-ripple-fg-opacity: $opacity; }
    .mdc-select--theme-dark .mdc-select__surface::before, .mdc-select--theme-dark .mdc-select__surface::after,
    .mdc-theme--dark .mdc-select__surface::before,
    .mdc-theme--dark .mdc-select__surface::after {
      background-color: white; }
    .mdc-select--theme-dark .mdc-select__surface:hover::before,
    .mdc-theme--dark .mdc-select__surface:hover::before {
      opacity: 0.08; }
    .mdc-select--theme-dark .mdc-select__surface:not(.mdc-ripple-upgraded):focus::before, .mdc-select--theme-dark .mdc-select__surface.mdc-ripple-upgraded--background-focused::before,
    .mdc-theme--dark .mdc-select__surface:not(.mdc-ripple-upgraded):focus::before,
    .mdc-theme--dark .mdc-select__surface.mdc-ripple-upgraded--background-focused::before {
      transition-duration: 75ms;
      opacity: 0.24; }
    .mdc-select--theme-dark .mdc-select__surface:not(.mdc-ripple-upgraded)::after,
    .mdc-theme--dark .mdc-select__surface:not(.mdc-ripple-upgraded)::after {
      transition: opacity 150ms linear; }
    .mdc-select--theme-dark .mdc-select__surface:not(.mdc-ripple-upgraded):active::after,
    .mdc-theme--dark .mdc-select__surface:not(.mdc-ripple-upgraded):active::after {
      transition-duration: 75ms;
      opacity: 0.32; }
    .mdc-select--theme-dark .mdc-select__surface.mdc-ripple-upgraded,
    .mdc-theme--dark .mdc-select__surface.mdc-ripple-upgraded {
      --mdc-ripple-fg-opacity: $opacity; }
    .mdc-select__surface::-ms-expand {
      display: none; }
  .mdc-select__label {
    left: 16px;
    right: initial;
    position: absolute;
    bottom: 12px;
    transform-origin: left top;
    transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1);
    color: rgba(0, 0, 0, 0.6);
    pointer-events: none;
    will-change: transform; }
    [dir="rtl"] .mdc-select__label, .mdc-select__label[dir="rtl"] {
      left: initial;
      right: 16px; }
    .mdc-select--theme-dark .mdc-select__label,
    .mdc-theme--dark .mdc-select__label {
      /* @alternate */
      color: rgba(255, 255, 255, 0.7);
      color: var(--mdc-theme-text-secondary-on-dark, rgba(255, 255, 255, 0.7)); }
    [dir="rtl"] .mdc-select .mdc-select__label,
    .mdc-select[dir="rtl"] .mdc-select__label {
      transform-origin: right top; }
    .mdc-select__label--float-above {
      transform: translateY(-40%) scale(0.75, 0.75); }
  .mdc-select__selected-text {
    display: flex;
    align-items: flex-end;
    margin-bottom: 6px;
    transition: opacity 125ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 125ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
    white-space: nowrap;
    overflow: hidden; }
    .mdc-select--theme-dark .mdc-select__selected-text,
    .mdc-theme--dark .mdc-select__selected-text {
      /* @alternate */
      color: rgba(255, 255, 255, 0.7);
      color: var(--mdc-theme-text-secondary-on-dark, rgba(255, 255, 255, 0.7)); }
  .mdc-select__bottom-line {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    transform: scaleY(1);
    transform-origin: bottom;
    transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1);
    background-color: rgba(0, 0, 0, 0.5); }
    .mdc-select__bottom-line::after {
      /* @alternate */
      background-color: #6200ee;
      background-color: var(--mdc-theme-primary, #6200ee);
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 2px;
      transform: scaleX(0);
      transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1);
      opacity: 0;
      content: "";
      z-index: 2; }
  .mdc-select__bottom-line--active::after {
    transform: scaleX(1);
    opacity: 1; }
  .mdc-select__surface:focus .mdc-select__bottom-line,
  .mdc-select__surface:focus ~ .mdc-select__bottom-line {
    /* @alternate */
    background-color: #6200ee;
    background-color: var(--mdc-theme-primary, #6200ee);
    transform: scaleY(2); }
    .mdc-select__surface:focus .mdc-select__bottom-line::after,
    .mdc-select__surface:focus ~ .mdc-select__bottom-line::after {
      opacity: 1; }

.mdc-select--open .mdc-select__surface::before {
  opacity: 0.12; }
  .mdc-select--theme-dark .mdc-select--open .mdc-select__surface::before,
  .mdc-theme--dark .mdc-select--open .mdc-select__surface::before {
    opacity: 0.24; }

.mdc-select--open .mdc-select__selected-text {
  transform: translateY(8px);
  transition: opacity 125ms 125ms cubic-bezier(0, 0, 0.2, 1), transform 125ms 125ms cubic-bezier(0, 0, 0.2, 1);
  opacity: 0; }

.mdc-select--open .mdc-select__bottom-line {
  /* @alternate */
  background-color: #6200ee;
  background-color: var(--mdc-theme-primary, #6200ee);
  transform: scaleY(2); }
  .mdc-select--open .mdc-select__bottom-line::after {
    opacity: 1; }

.mdc-select--disabled,
.mdc-select[disabled] {
  /* @alternate */
  color: rgba(0, 0, 0, 0.38);
  color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38));
  background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%230%22%20fill-rule%3D%22evenodd%22%20opacity%3D%220.38%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E);
  border-bottom-width: 1px;
  border-bottom-style: dotted;
  opacity: .38;
  cursor: default;
  pointer-events: none;
  user-select: none; }
  .mdc-select--disabled .mdc-select__bottom-line,
  .mdc-select[disabled] .mdc-select__bottom-line {
    display: none; }

.mdc-select--theme-dark.mdc-select--disabled,
.mdc-theme--dark .mdc-select--disabled {
  /* @alternate */
  color: rgba(255, 255, 255, 0.5);
  color: var(--mdc-theme-text-disabled-on-dark, rgba(255, 255, 255, 0.5));
  background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%23ffffff%22%20fill-rule%3D%22evenodd%22%20opacity%3D%220.38%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E);
  border-bottom: 1px dotted rgba(255, 255, 255, 0.38); }

.mdc-select--theme-dark.mdc-select[disabled],
.mdc-theme--dark .mdc-select[disabled] {
  /* @alternate */
  color: rgba(255, 255, 255, 0.5);
  color: var(--mdc-theme-text-disabled-on-dark, rgba(255, 255, 255, 0.5));
  background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%23ffffff%22%20fill-rule%3D%22evenodd%22%20opacity%3D%220.38%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E);
  border-bottom: 1px dotted rgba(255, 255, 255, 0.38); }

.mdc-select__menu .mdc-list-item {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.04em;
  text-decoration: inherit;
  text-transform: inherit;
  /* @alternate */
  color: rgba(0, 0, 0, 0.54);
  color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54)); }
  .mdc-select__menu .mdc-list-item[aria-selected="true"] {
    /* @alternate */
    color: rgba(0, 0, 0, 0.87);
    color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)); }
  .mdc-select--theme-dark .mdc-select__menu .mdc-list-item,
  .mdc-theme--dark .mdc-select__menu .mdc-list-item {
    /* @alternate */
    color: rgba(255, 255, 255, 0.7);
    color: var(--mdc-theme-text-secondary-on-dark, rgba(255, 255, 255, 0.7)); }
    .mdc-select--theme-dark .mdc-select__menu .mdc-list-item[aria-selected="true"],
    .mdc-theme--dark .mdc-select__menu .mdc-list-item[aria-selected="true"] {
      /* @alternate */
      color: white;
      color: var(--mdc-theme-text-primary-on-dark, white); }
  .mdc-select__menu .mdc-list-item::before, .mdc-select__menu .mdc-list-item::after {
    top: calc(50% - 50%);
    left: calc(50% - 50%);
    width: 100%;
    height: 100%; }
  .mdc-select__menu .mdc-list-item.mdc-ripple-upgraded::before {
    top: calc(50% - 50%);
    left: calc(50% - 50%);
    width: 100%;
    height: 100%;
    transform: scale(var(--mdc-ripple-fg-scale, 0)); }
  .mdc-select__menu .mdc-list-item.mdc-ripple-upgraded--unbounded::before {
    top: var(--mdc-ripple-top, calc(50% - 25%));
    left: var(--mdc-ripple-left, calc(50% - 25%));
    width: var(--mdc-ripple-fg-size, 50%);
    height: var(--mdc-ripple-fg-size, 50%);
    transform: scale(var(--mdc-ripple-fg-scale, 0)); }
  .mdc-select__menu .mdc-list-item.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 50%);
    height: var(--mdc-ripple-fg-size, 50%); }
  .mdc-select__menu .mdc-list-item::before, .mdc-select__menu .mdc-list-item::after {
    border-radius: 0; }

.mdc-select__menu .mdc-list-group,
.mdc-select__menu .mdc-list-group > .mdc-list-item:first-child {
  margin-top: 12px; }

.mdc-select__menu .mdc-list-group {
  /* @alternate */
  color: rgba(0, 0, 0, 0.38);
  color: var(--mdc-theme-text-hint-on-light, rgba(0, 0, 0, 0.38));
  font-weight: normal; }
  .mdc-select__menu .mdc-list-group .mdc-list-item {
    /* @alternate */
    color: rgba(0, 0, 0, 0.87);
    color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)); }

.mdc-select--theme-dark .mdc-select__menu .mdc-list-group,
.mdc-theme--dark .mdc-select__menu .mdc-list-group {
  /* @alternate */
  color: rgba(255, 255, 255, 0.5);
  color: var(--mdc-theme-text-hint-on-dark, rgba(255, 255, 255, 0.5)); }
  .mdc-select--theme-dark .mdc-select__menu .mdc-list-group .mdc-list-item,
  .mdc-theme--dark .mdc-select__menu .mdc-list-group .mdc-list-item {
    /* @alternate */
    color: white;
    color: var(--mdc-theme-text-primary-on-dark, white); }

.mdc-multi-select {
  /* @alternate */
  border-color: rgba(0, 0, 0, 0.38);
  border-color: var(--mdc-theme-text-hint-on-light, rgba(0, 0, 0, 0.38));
  width: 250px;
  padding: 0;
  border-width: 1px;
  border-style: solid;
  outline: none;
  appearance: none; }
  .mdc-multi-select--theme-dark,
  .mdc-theme--dark .mdc-multi-select {
    /* @alternate */
    border-color: rgba(255, 255, 255, 0.5);
    border-color: var(--mdc-theme-text-hint-on-dark, rgba(255, 255, 255, 0.5)); }
  .mdc-multi-select .mdc-list-group {
    /* @alternate */
    color: rgba(0, 0, 0, 0.38);
    color: var(--mdc-theme-text-hint-on-light, rgba(0, 0, 0, 0.38));
    margin: 16px 0 0;
    padding: 0 0 0 16px;
    font-weight: normal; }
    .mdc-multi-select .mdc-list-group--theme-dark,
    .mdc-theme--dark .mdc-multi-select .mdc-list-group {
      /* @alternate */
      color: rgba(255, 255, 255, 0.5);
      color: var(--mdc-theme-text-hint-on-dark, rgba(255, 255, 255, 0.5)); }
    .mdc-multi-select .mdc-list-group:last-child {
      margin-bottom: 16px; }
    .mdc-multi-select .mdc-list-group .mdc-list-divider {
      margin-left: -16px; }
  .mdc-multi-select .mdc-list-item {
    /* @alternate */
    color: rgba(0, 0, 0, 0.87);
    color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
    margin: 0 0 0 -16px;
    padding: 0 16px; }
    .mdc-multi-select .mdc-list-item--theme-dark,
    .mdc-theme--dark .mdc-multi-select .mdc-list-item {
      /* @alternate */
      color: white;
      color: var(--mdc-theme-text-primary-on-dark, white); }
    .mdc-multi-select .mdc-list-item:first-child {
      margin-top: 12px; }
    .mdc-multi-select .mdc-list-item:last-child {
      margin-bottom: 8px; }
    .mdc-multi-select .mdc-list-item::before, .mdc-multi-select .mdc-list-item::after {
      content: none; }
  .mdc-multi-select .mdc-list-item:checked {
    /* @alternate */
    background-color: #fff;
    background-color: var(--mdc-theme-background, #fff); }
    .mdc-multi-select .mdc-list-item:checked--theme-dark,
    .mdc-theme--dark .mdc-multi-select .mdc-list-item:checked {
      /* @alternate */
      background-color: white;
      background-color: var(--mdc-theme-text-primary-on-dark, white); }
  .mdc-multi-select .mdc-list-divider {
    margin-bottom: 8px;
    padding-top: 8px;
    font-size: 0; }

.mdc-multi-select:focus .mdc-list-item:checked {
  /* @alternate */
  background-color: #6200ee;
  background-color: var(--mdc-theme-primary, #6200ee); }
  .mdc-multi-select:focus .mdc-list-item:checked--theme-dark,
  .mdc-theme--dark .mdc-multi-select:focus .mdc-list-item:checked {
    /* @alternate */
    background-color: white;
    background-color: var(--mdc-theme-text-primary-on-dark, white); }

.mdc-select-scroll-lock {
  overflow: hidden; }

/**
 * The css property used for elevation. In most cases this should not be changed. It is exposed
 * as a variable for abstraction / easy use when needing to reference the property directly, for
 * example in a `will-change` rule.
 */
/**
 * The default duration value for elevation transitions.
 */
/**
 * The default easing value for elevation transitions.
 */
/**
 * Applies the correct CSS rules to an element to give it the elevation specified by $z-value.
 * The $z-value must be between 0 and 24.
 * If $color has an alpha channel, it will be ignored and overridden. To increase the opacity of the shadow, use
 * $opacity-boost.
 */
/**
 * Returns a string that can be used as the value for a `transition` property for elevation.
 * Calling this function directly is useful in situations where a component needs to transition
 * more than one property.
 *
 * ```scss
 * .foo {
 *   transition: mdc-elevation-transition-value(), opacity 100ms ease;
 *   will-change: $mdc-elevation-property, opacity;
 * }
 * ```
 */
.mdc-switch {
  display: inline-block;
  position: relative; }
  .mdc-switch .mdc-switch__native-control:enabled:not(:checked) ~ .mdc-switch__background::before {
    background-color: #000; }
  .mdc-switch .mdc-switch__native-control:enabled:not(:checked) ~ .mdc-switch__background .mdc-switch__knob {
    background-color: #fafafa; }
  .mdc-switch .mdc-switch__native-control:enabled:not(:checked) ~ .mdc-switch__background .mdc-switch__knob::before {
    background-color: #9e9e9e; }
  .mdc-switch--theme-dark .mdc-switch__native-control:enabled:not(:checked) ~ .mdc-switch__background::before,
  .mdc-theme--dark .mdc-switch .mdc-switch__native-control:enabled:not(:checked) ~ .mdc-switch__background::before {
    background-color: #fff; }
  .mdc-switch--theme-dark .mdc-switch__native-control:enabled:not(:checked) ~ .mdc-switch__background .mdc-switch__knob,
  .mdc-theme--dark .mdc-switch .mdc-switch__native-control:enabled:not(:checked) ~ .mdc-switch__background .mdc-switch__knob {
    background-color: #bdbdbd; }
  .mdc-switch--theme-dark .mdc-switch__native-control:enabled:not(:checked) ~ .mdc-switch__background .mdc-switch__knob::before,
  .mdc-theme--dark .mdc-switch .mdc-switch__native-control:enabled:not(:checked) ~ .mdc-switch__background .mdc-switch__knob::before {
    background-color: #f1f1f1; }
  .mdc-switch .mdc-switch__native-control:enabled:checked ~ .mdc-switch__background::before {
    /* @alternate */
    background-color: #018786;
    background-color: var(--mdc-theme-secondary, #018786); }
  .mdc-switch .mdc-switch__native-control:enabled:checked ~ .mdc-switch__background .mdc-switch__knob {
    /* @alternate */
    background-color: #018786;
    background-color: var(--mdc-theme-secondary, #018786); }
  .mdc-switch .mdc-switch__native-control:enabled:checked ~ .mdc-switch__background .mdc-switch__knob::before {
    /* @alternate */
    background-color: #018786;
    background-color: var(--mdc-theme-secondary, #018786); }
  .mdc-switch__native-control {
    position: absolute;
    top: -14px;
    left: -14px;
    width: 48px;
    height: 48px;
    display: inline-block;
    margin-top: -3px;
    transition: transform 90ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
    cursor: pointer;
    z-index: 2; }
    .mdc-switch__native-control:checked {
      transform: translateX(14px); }
  .mdc-switch__background {
    display: block;
    position: relative;
    width: 34px;
    height: 14px;
    border-radius: 7px;
    outline: none;
    user-select: none; }
    .mdc-switch__background::before {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: opacity 90ms cubic-bezier(0.4, 0, 0.2, 1), background-color 90ms cubic-bezier(0.4, 0, 0.2, 1);
      border-radius: 7px;
      opacity: .38;
      content: ""; }
      .mdc-switch--theme-dark .mdc-switch__background::before,
      .mdc-theme--dark .mdc-switch__background::before {
        opacity: .3; }
    .mdc-switch__background .mdc-switch__knob {
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
      display: block;
      position: absolute;
      top: -3px;
      left: 0;
      width: 20px;
      height: 20px;
      transform: translateX(0);
      transition: transform 90ms cubic-bezier(0.4, 0, 0.2, 1), background-color 90ms cubic-bezier(0.4, 0, 0.2, 1);
      border-radius: 10px;
      z-index: 1; }
      .mdc-switch__background .mdc-switch__knob::before {
        position: absolute;
        top: -14px;
        left: -14px;
        width: 48px;
        height: 48px;
        transform: scale(0);
        transition: transform 90ms cubic-bezier(0.4, 0, 0.2, 1), background-color 90ms cubic-bezier(0.4, 0, 0.2, 1);
        border-radius: 24px;
        opacity: .2;
        content: ""; }

.mdc-switch__native-control:focus ~ .mdc-switch__background .mdc-switch__knob::before {
  position: absolute;
  width: 48px;
  height: 48px;
  transform: scale(1);
  transition: transform 90ms cubic-bezier(0.4, 0, 0.2, 1), background-color 90ms cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 24px; }
  .mdc-switch--theme-dark .mdc-switch__native-control:focus ~ .mdc-switch__background .mdc-switch__knob::before,
  .mdc-theme--dark .mdc-switch__native-control:focus ~ .mdc-switch__background .mdc-switch__knob::before {
    opacity: .14; }

.mdc-switch__native-control:checked ~ .mdc-switch__background::before {
  opacity: .5; }

.mdc-switch__native-control:checked ~ .mdc-switch__background .mdc-switch__knob {
  transform: translateX(14px);
  transition: transform 90ms cubic-bezier(0.4, 0, 0.2, 1), background-color 90ms cubic-bezier(0.4, 0, 0.2, 1); }
  .mdc-switch__native-control:checked ~ .mdc-switch__background .mdc-switch__knob::before {
    opacity: .15; }

.mdc-switch__native-control:disabled {
  cursor: initial; }

.mdc-switch__native-control:disabled ~ .mdc-switch__background::before {
  background-color: #000;
  opacity: .12; }
  .mdc-switch--theme-dark .mdc-switch__native-control:disabled ~ .mdc-switch__background::before,
  .mdc-theme--dark .mdc-switch__native-control:disabled ~ .mdc-switch__background::before {
    background-color: #fff;
    opacity: .1; }

.mdc-switch__native-control:disabled ~ .mdc-switch__background .mdc-switch__knob {
  background-color: #bdbdbd; }
  .mdc-switch--theme-dark .mdc-switch__native-control:disabled ~ .mdc-switch__background .mdc-switch__knob,
  .mdc-theme--dark .mdc-switch__native-control:disabled ~ .mdc-switch__background .mdc-switch__knob {
    background-color: #424242; }

.vc-analytics__view-select-container {
  padding: 24px; }

.vc-analytics__filters {
  width: 100%;
  display: inline-block; }

.vc-analytics__filter-container, .vc-analytics__load-view-container {
  display: inline-block; }

.vc-analytics__load-view-container {
  padding: 12px; }
  .vc-analytics__load-view-container label {
    margin: 0 5px; }
  .vc-analytics__load-view-container .mdc-switch .mdc-switch__native-control:enabled:not(:checked) ~ .mdc-switch__background::before {
    background-color: #90CAF9; }
  .vc-analytics__load-view-container .mdc-switch .mdc-switch__native-control:enabled:not(:checked) ~ .mdc-switch__background .mdc-switch__knob {
    background-color: #2196F3; }
    .vc-analytics__load-view-container .mdc-switch .mdc-switch__native-control:enabled:not(:checked) ~ .mdc-switch__background .mdc-switch__knob::before {
      background-color: #90CAF9; }

.vc-analytics__filter-label {
  padding: 12px;
  display: inline-block;
  cursor: pointer; }
  .vc-analytics__filter-label:hover {
    background-color: #EFEFEF; }

.vc-analytics__filter-options {
  height: 0;
  background-color: #EFEFEF;
  clear: both;
  overflow: hidden;
  transition: height 0.5s; }
  .vc-analytics__filter-options.vc-analytics__filter--open {
    height: 180px; }

.vc-analytics__quick-filter-item {
  color: #1976D2;
  cursor: pointer;
  padding: 10px 0; }
  .vc-analytics__quick-filter-item.active {
    background-color: #DEDEDE; }
  .vc-analytics__quick-filter-item:hover {
    background-color: #DEDEDE; }

.vc-analytics__section {
  margin-top: 20px; }
  .vc-analytics__section:first-child {
    margin-top: 0; }

.vc-analytics__vitals-heading {
  font-size: 14px; }

.vc-analytics__vitals-value {
  font-size: 32px; }

.vc-analytics__pie .vc-analytics__pie-heading {
  font-size: 13px;
  font-weight: bold; }

.vc-analytics__pie .vc-analytics__pie-label {
  font-size: 12px;
  color: #BCBCBC;
  padding: 20px 0; }

.vc-analytics__pie .recharts-wrapper {
  display: inline-block; }

.vc-analytics__table-heading {
  font-size: 20px; }

.vc-analytics__table {
  margin-top: 10px;
  border: 1px solid #DEDEDE;
  border-spacing: 0; }
  .vc-analytics__table th, .vc-analytics__table td {
    border: 1px solid #DEDEDE;
    border-top: 0;
    border-left: 0;
    padding: 10px;
    margin: 0; }
  .vc-analytics__table th:last-child, .vc-analytics__table td:last-child {
    border-right: 0; }
  .vc-analytics__table tr:last-child td {
    border-bottom: 0; }
  .vc-analytics__table th {
    text-align: left; }

.vc-analytics-realtime__container {
  padding: 24px; }
