.vc-analytics-single-video__container .vc-analytics__back {
  margin-left: calc(5% - 10px);
  padding: 10px;
  display: inline-block; }
  .vc-analytics-single-video__container .vc-analytics__back i {
    vertical-align: middle; }

.vc-analytics-single-video__container .vc-analytics-single-video__video-id {
  margin: 20px 0 0 5%; }

.vc-analytics-single-video__container .vc-analytics-videos__line-chart {
  margin: 40px 0 0; }
