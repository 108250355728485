@keyframes mdc-ripple-fg-radius-in {
  from {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1); }
  to {
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); } }

@keyframes mdc-ripple-fg-opacity-in {
  from {
    animation-timing-function: linear;
    opacity: 0; }
  to {
    opacity: var(--mdc-ripple-fg-opacity, 0.16); } }

@keyframes mdc-ripple-fg-opacity-out {
  from {
    animation-timing-function: linear;
    opacity: var(--mdc-ripple-fg-opacity, 0.16); }
  to {
    opacity: 0; } }

.mdc-ripple-surface--test-edge-var-bug {
  --mdc-ripple-surface-test-edge-var: 1px solid #000;
  visibility: hidden; }
  .mdc-ripple-surface--test-edge-var-bug::before {
    border: var(--mdc-ripple-surface-test-edge-var); }

/**
 * Creates a rule that will be applied when an MDC-Web component is within the context of an RTL layout.
 *
 * Usage Example:
 * ```scss
 * .mdc-foo {
 *   position: absolute;
 *   left: 0;
 *
 *   @include mdc-rtl {
 *     left: auto;
 *     right: 0;
 *   }
 *
 *   &__bar {
 *     margin-left: 4px;
 *     @include mdc-rtl(".mdc-foo") {
 *       margin-left: auto;
 *       margin-right: 4px;
 *     }
 *   }
 * }
 *
 * .mdc-foo--mod {
 *   padding-left: 4px;
 *
 *   @include mdc-rtl {
 *     padding-left: auto;
 *     padding-right: 4px;
 *   }
 * }
 * ```
 *
 * Note that this works by checking for [dir="rtl"] on an ancestor element. While this will work
 * in most cases, it will in some cases lead to false negatives, e.g.
 *
 * ```html
 * <html dir="rtl">
 *   <!-- ... -->
 *   <div dir="ltr">
 *     <div class="mdc-foo">Styled incorrectly as RTL!</div>
 *   </div>
 * </html>
 * ```
 *
 * In the future, selectors such as :dir (http://mdn.io/:dir) will help us mitigate this.
 */
/**
 * Takes a base box-model property - e.g. margin / border / padding - along with a default
 * direction and value, and emits rules which apply the value to the
 * "<base-property>-<default-direction>" property by default, but flips the direction
 * when within an RTL context.
 *
 * For example:
 *
 * ```scss
 * .mdc-foo {
 *   @include mdc-rtl-reflexive-box(margin, left, 8px);
 * }
 * ```
 * is equivalent to:
 *
 * ```scss
 * .mdc-foo {
 *   margin-left: 8px;
 *
 *   @include mdc-rtl {
 *     margin-right: 8px;
 *     margin-left: 0;
 *   }
 * }
 * ```
 * whereas:
 *
 * ```scss
 * .mdc-foo {
 *   @include mdc-rtl-reflexive-box(margin, right, 8px);
 * }
 * ```
 * is equivalent to:
 *
 * ```scss
 * .mdc-foo {
 *   margin-right: 8px;
 *
 *   @include mdc-rtl {
 *     margin-right: 0;
 *     margin-left: 8px;
 *   }
 * }
 * ```
 *
 * You can also pass a 4th optional $root-selector argument which will be forwarded to `mdc-rtl`,
 * e.g. `@include mdc-rtl-reflexive-box(margin, left, 8px, ".mdc-component")`.
 *
 * Note that this function will always zero out the original value in an RTL context. If you're
 * trying to flip the values, use mdc-rtl-reflexive-property().
 */
/**
 * Takes a base property and emits rules that assign <base-property>-left to <left-value> and
 * <base-property>-right to <right-value> in a LTR context, and vice versa in a RTL context.
 * For example:
 *
 * ```scss
 * .mdc-foo {
 *   @include mdc-rtl-reflexive-property(margin, auto, 12px);
 * }
 * ```
 * is equivalent to:
 *
 * ```scss
 * .mdc-foo {
 *   margin-left: auto;
 *   margin-right: 12px;
 *
 *   @include mdc-rtl {
 *     margin-left: 12px;
 *     margin-right: auto;
 *   }
 * }
 * ```
 *
 * A 4th optional $root-selector argument can be given, which will be passed to `mdc-rtl`.
 */
/**
 * Takes an argument specifying a horizontal position property (either "left" or "right") as well
 * as a value, and applies that value to the specified position in a LTR context, and flips it in a
 * RTL context. For example:
 *
 * ```scss
 * .mdc-foo {
 *   @include mdc-rtl-reflexive-position(left, 0);
 *   position: absolute;
 * }
 * ```
 * is equivalent to:
 *
 * ```scss
 *  .mdc-foo {
 *    position: absolute;
 *    left: 0;
 *    right: initial;
 *
 *    @include mdc-rtl {
 *      right: 0;
 *      left: initial;
 *    }
 *  }
 * ```
 * An optional third $root-selector argument may also be given, which is passed to `mdc-rtl`.
 */
/* TODO(sgomes): Figure out what to do about desktop font sizes. */
/* TODO(sgomes): Figure out what to do about i18n and i18n font sizes. */
/* TODO(sgomes): Figure out what to do about desktop font sizes. */
/* TODO(sgomes): Figure out what to do about i18n and i18n font sizes. */
.mdc-text-field__bottom-line {
  /* @alternate */
  background-color: #6200ee;
  background-color: var(--mdc-theme-primary, #6200ee);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  transform: scaleX(0);
  transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1), opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  z-index: 2; }

.mdc-text-field__bottom-line--active {
  transform: scaleX(1); }

.mdc-text-field .mdc-text-field__input:focus ~ .mdc-text-field__bottom-line {
  opacity: 1; }

.mdc-text-field-helper-text {
  /* @alternate */
  color: rgba(0, 0, 0, 0.38);
  color: var(--mdc-theme-text-hint-on-light, rgba(0, 0, 0, 0.38));
  margin: 0;
  transition: opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  font-size: .75rem;
  will-change: opacity; }
  .mdc-text-field-helper-text--theme-dark,
  .mdc-theme--dark .mdc-text-field-helper-text {
    /* @alternate */
    color: rgba(255, 255, 255, 0.5);
    color: var(--mdc-theme-text-hint-on-dark, rgba(255, 255, 255, 0.5)); }
  .mdc-text-field + .mdc-text-field-helper-text {
    margin-bottom: 8px; }

.mdc-text-field-helper-text--persistent {
  transition: none;
  opacity: 1;
  will-change: initial; }

.mdc-text-field--with-leading-icon .mdc-text-field__icon,
.mdc-text-field--with-trailing-icon .mdc-text-field__icon {
  position: absolute;
  bottom: 16px;
  cursor: pointer; }
  .mdc-text-field--theme-dark .mdc-text-field--with-leading-icon .mdc-text-field__icon,
  .mdc-theme--dark .mdc-text-field--with-leading-icon .mdc-text-field__icon, .mdc-text-field--theme-dark
  .mdc-text-field--with-trailing-icon .mdc-text-field__icon,
  .mdc-theme--dark
  .mdc-text-field--with-trailing-icon .mdc-text-field__icon {
    /* @alternate */
    color: rgba(255, 255, 255, 0.7);
    color: var(--mdc-theme-text-secondary-on-dark, rgba(255, 255, 255, 0.7)); }

.mdc-text-field__icon:not([tabindex]),
.mdc-text-field__icon[tabindex="-1"] {
  cursor: default;
  pointer-events: none; }

/**
 * Creates a rule that will be applied when an MDC-Web component is within the context of an RTL layout.
 *
 * Usage Example:
 * ```scss
 * .mdc-foo {
 *   position: absolute;
 *   left: 0;
 *
 *   @include mdc-rtl {
 *     left: auto;
 *     right: 0;
 *   }
 *
 *   &__bar {
 *     margin-left: 4px;
 *     @include mdc-rtl(".mdc-foo") {
 *       margin-left: auto;
 *       margin-right: 4px;
 *     }
 *   }
 * }
 *
 * .mdc-foo--mod {
 *   padding-left: 4px;
 *
 *   @include mdc-rtl {
 *     padding-left: auto;
 *     padding-right: 4px;
 *   }
 * }
 * ```
 *
 * Note that this works by checking for [dir="rtl"] on an ancestor element. While this will work
 * in most cases, it will in some cases lead to false negatives, e.g.
 *
 * ```html
 * <html dir="rtl">
 *   <!-- ... -->
 *   <div dir="ltr">
 *     <div class="mdc-foo">Styled incorrectly as RTL!</div>
 *   </div>
 * </html>
 * ```
 *
 * In the future, selectors such as :dir (http://mdn.io/:dir) will help us mitigate this.
 */
/**
 * Takes a base box-model property - e.g. margin / border / padding - along with a default
 * direction and value, and emits rules which apply the value to the
 * "<base-property>-<default-direction>" property by default, but flips the direction
 * when within an RTL context.
 *
 * For example:
 *
 * ```scss
 * .mdc-foo {
 *   @include mdc-rtl-reflexive-box(margin, left, 8px);
 * }
 * ```
 * is equivalent to:
 *
 * ```scss
 * .mdc-foo {
 *   margin-left: 8px;
 *
 *   @include mdc-rtl {
 *     margin-right: 8px;
 *     margin-left: 0;
 *   }
 * }
 * ```
 * whereas:
 *
 * ```scss
 * .mdc-foo {
 *   @include mdc-rtl-reflexive-box(margin, right, 8px);
 * }
 * ```
 * is equivalent to:
 *
 * ```scss
 * .mdc-foo {
 *   margin-right: 8px;
 *
 *   @include mdc-rtl {
 *     margin-right: 0;
 *     margin-left: 8px;
 *   }
 * }
 * ```
 *
 * You can also pass a 4th optional $root-selector argument which will be forwarded to `mdc-rtl`,
 * e.g. `@include mdc-rtl-reflexive-box(margin, left, 8px, ".mdc-component")`.
 *
 * Note that this function will always zero out the original value in an RTL context. If you're
 * trying to flip the values, use mdc-rtl-reflexive-property().
 */
/**
 * Takes a base property and emits rules that assign <base-property>-left to <left-value> and
 * <base-property>-right to <right-value> in a LTR context, and vice versa in a RTL context.
 * For example:
 *
 * ```scss
 * .mdc-foo {
 *   @include mdc-rtl-reflexive-property(margin, auto, 12px);
 * }
 * ```
 * is equivalent to:
 *
 * ```scss
 * .mdc-foo {
 *   margin-left: auto;
 *   margin-right: 12px;
 *
 *   @include mdc-rtl {
 *     margin-left: 12px;
 *     margin-right: auto;
 *   }
 * }
 * ```
 *
 * A 4th optional $root-selector argument can be given, which will be passed to `mdc-rtl`.
 */
/**
 * Takes an argument specifying a horizontal position property (either "left" or "right") as well
 * as a value, and applies that value to the specified position in a LTR context, and flips it in a
 * RTL context. For example:
 *
 * ```scss
 * .mdc-foo {
 *   @include mdc-rtl-reflexive-position(left, 0);
 *   position: absolute;
 * }
 * ```
 * is equivalent to:
 *
 * ```scss
 *  .mdc-foo {
 *    position: absolute;
 *    left: 0;
 *    right: initial;
 *
 *    @include mdc-rtl {
 *      right: 0;
 *      left: initial;
 *    }
 *  }
 * ```
 * An optional third $root-selector argument may also be given, which is passed to `mdc-rtl`.
 */
@keyframes invalid-shake-float-above-standard {
  0% {
    transform: translateX(0) translateY(-100%) scale(0.75); }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    transform: translateX(5px) translateY(-100%) scale(0.75); }
  66% {
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    transform: translateX(-5px) translateY(-100%) scale(0.75); }
  100% {
    transform: translateX(0) translateY(-100%) scale(0.75); } }

@keyframes invalid-shake-float-above-box {
  0% {
    transform: translateX(0) translateY(-50%) scale(0.75); }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    transform: translateX(5px) translateY(-50%) scale(0.75); }
  66% {
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    transform: translateX(-5px) translateY(-50%) scale(0.75); }
  100% {
    transform: translateX(0) translateY(-50%) scale(0.75); } }

.mdc-text-field__label {
  position: absolute;
  bottom: 8px;
  left: 0;
  transform-origin: left top;
  transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1), color 180ms cubic-bezier(0.4, 0, 0.2, 1);
  color: rgba(0, 0, 0, 0.6);
  cursor: text; }
  [dir="rtl"] .mdc-text-field .mdc-text-field__label,
  .mdc-text-field[dir="rtl"] .mdc-text-field__label {
    right: 0;
    left: auto;
    transform-origin: right top; }
  .mdc-text-field--theme-dark .mdc-text-field__label,
  .mdc-theme--dark .mdc-text-field__label {
    color: rgba(255, 255, 255, 0.6); }
  .mdc-text-field__label--float-above {
    transform: translateY(-100%) scale(0.75, 0.75);
    cursor: auto; }

.mdc-text-field__label--float-above.mdc-text-field__label--shake {
  animation: invalid-shake-float-above-standard 250ms 1; }

.mdc-text-field__idle-outline {
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  transition: opacity 100ms ease;
  border: 1px solid rgba(0, 0, 0, 0.12);
  opacity: 1; }

.mdc-text-field__outline {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  /* @alternate */
  color: #6200ee;
  color: var(--mdc-theme-primary, #6200ee);
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 1px);
  height: calc(100% - 2px);
  transition: opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  overflow: hidden; }
  .mdc-text-field__outline::before, .mdc-text-field__outline::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-text-field__outline::before {
    transition: opacity 15ms linear; }
  .mdc-text-field__outline.mdc-ripple-upgraded::after {
    top: 0;
    left: 0;
    transform: scale(0);
    transform-origin: center center; }
  .mdc-text-field__outline.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    left: var(--mdc-ripple-left, 0); }
  .mdc-text-field__outline.mdc-ripple-upgraded--foreground-activation::after {
    animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
  .mdc-text-field__outline.mdc-ripple-upgraded--foreground-deactivation::after {
    animation: 150ms mdc-ripple-fg-opacity-out;
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-text-field__outline::before, .mdc-text-field__outline::after {
    top: calc(50% - 100%);
    left: calc(50% - 100%);
    width: 200%;
    height: 200%; }
  .mdc-text-field__outline.mdc-ripple-upgraded::before {
    top: calc(50% - 100%);
    left: calc(50% - 100%);
    width: 200%;
    height: 200%;
    transform: scale(var(--mdc-ripple-fg-scale, 0)); }
  .mdc-text-field__outline.mdc-ripple-upgraded--unbounded::before {
    top: var(--mdc-ripple-top, calc(50% - 50%));
    left: var(--mdc-ripple-left, calc(50% - 50%));
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%);
    transform: scale(var(--mdc-ripple-fg-scale, 0)); }
  .mdc-text-field__outline.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-text-field__outline::before, .mdc-text-field__outline::after {
    /* @alternate */
    background-color: rgba(0, 0, 0, 0.87); }
    @supports not (-ms-ime-align: auto) {
      .mdc-text-field__outline::before, .mdc-text-field__outline::after {
        background-color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)); } }
  .mdc-text-field__outline:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-text-field__outline:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.16; }
  .mdc-text-field__outline.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: $opacity; }
  .mdc-text-field__outline svg {
    position: absolute;
    width: 100%;
    height: 100%; }
    .mdc-text-field__outline svg .mdc-text-field__outline-path {
      stroke: rgba(0, 0, 0, 0.12);
      stroke-width: 1px;
      transition: stroke 180ms cubic-bezier(0.4, 0, 0.2, 1), stroke-width 180ms cubic-bezier(0.4, 0, 0.2, 1), opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
      fill: transparent; }

@keyframes invalid-shake-float-above-standard {
  0% {
    transform: translateX(0) translateY(-100%) scale(0.75); }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    transform: translateX(5px) translateY(-100%) scale(0.75); }
  66% {
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    transform: translateX(-5px) translateY(-100%) scale(0.75); }
  100% {
    transform: translateX(0) translateY(-100%) scale(0.75); } }

@keyframes invalid-shake-float-above-box {
  0% {
    transform: translateX(0) translateY(-50%) scale(0.75); }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    transform: translateX(5px) translateY(-50%) scale(0.75); }
  66% {
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    transform: translateX(-5px) translateY(-50%) scale(0.75); }
  100% {
    transform: translateX(0) translateY(-50%) scale(0.75); } }

@keyframes invalid-shake-float-above-outlined {
  0% {
    transform: translateX(0) translateY(-130%) scale(0.75); }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    transform: translateX(5px) translateY(-130%) scale(0.75); }
  66% {
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    transform: translateX(-5px) translateY(-130%) scale(0.75); }
  100% {
    transform: translateX(0) translateY(-130%) scale(0.75); } }

@keyframes invalid-shake-float-above-outlined-dense {
  0% {
    transform: translateX(0) translateY(-138%) scale(0.923); }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    transform: translateX(5px) translateY(-138%) scale(0.923); }
  66% {
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    transform: translateX(-5px) translateY(-138%) scale(0.923); }
  100% {
    transform: translateX(0) translateY(-138%) scale(0.923); } }

.mdc-text-field {
  display: inline-block;
  position: relative;
  margin-bottom: 8px;
  will-change: opacity, transform, color; }

.mdc-text-field__input {
  /* @alternate */
  color: rgba(0, 0, 0, 0.87);
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.04em;
  width: 100%;
  padding: 0 0 8px;
  transition: opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 0;
  background: none;
  font-size: inherit;
  appearance: none; }
  .mdc-text-field__input::placeholder {
    /* @alternate */
    color: rgba(0, 0, 0, 0.38);
    color: var(--mdc-theme-text-hint-on-light, rgba(0, 0, 0, 0.38));
    transition: color 180ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 1; }
  .mdc-text-field__input:hover {
    border-color: black; }
  .mdc-text-field__input:focus {
    outline: none; }
    .mdc-text-field__input:focus::placeholder {
      color: rgba(0, 0, 0, 0.38); }
  .mdc-text-field__input:invalid {
    box-shadow: none; }
  .mdc-text-field__input--theme-dark,
  .mdc-theme--dark .mdc-text-field__input {
    /* @alternate */
    color: white;
    color: var(--mdc-theme-text-primary-on-dark, white);
    border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
    .mdc-text-field__input--theme-dark:hover,
    .mdc-theme--dark .mdc-text-field__input:hover {
      border-bottom: 1px solid white; }
    .mdc-text-field__input--theme-dark::placeholder,
    .mdc-theme--dark .mdc-text-field__input::placeholder {
      /* @alternate */
      color: rgba(255, 255, 255, 0.5);
      color: var(--mdc-theme-text-hint-on-dark, rgba(255, 255, 255, 0.5)); }
    .mdc-text-field__input--theme-dark:focus::placeholder,
    .mdc-theme--dark .mdc-text-field__input:focus::placeholder {
      color: rgba(255, 255, 255, 0.3); }

.mdc-text-field__input:-webkit-autofill + .mdc-text-field__label {
  transform: translateY(-100%) scale(0.75, 0.75);
  cursor: auto; }

.mdc-text-field--outlined {
  height: 56px;
  border: none; }
  .mdc-text-field--outlined .mdc-text-field__input {
    display: flex;
    height: 30px;
    padding: 12px;
    border: none;
    background-color: transparent;
    z-index: 1; }
    .mdc-text-field--outlined .mdc-text-field__input:hover ~ .mdc-text-field__idle-outline {
      border: 1px solid rgba(0, 0, 0, 0.87); }
  .mdc-text-field--outlined .mdc-text-field__label {
    left: 16px;
    right: initial;
    position: absolute;
    bottom: 20px;
    transition: transform 260ms ease; }
    [dir="rtl"] .mdc-text-field--outlined .mdc-text-field__label, .mdc-text-field--outlined .mdc-text-field__label[dir="rtl"] {
      left: initial;
      right: 16px; }
    .mdc-text-field--outlined .mdc-text-field__label--float-above {
      transform: scale(0.75) translateY(-170%); }
      .mdc-text-field--outlined .mdc-text-field__label--float-above.mdc-text-field__label--shake {
        animation: invalid-shake-float-above-outlined 250ms 1; }
  .mdc-text-field--outlined .mdc-text-field__icon {
    z-index: 2; }
    .mdc-text-field--outlined .mdc-text-field__icon:hover ~ .mdc-text-field__idle-outline {
      border: 1px solid rgba(0, 0, 0, 0.87); }
  .mdc-text-field--outlined.mdc-text-field--focused .mdc-text-field__idle-outline,
  .mdc-text-field--outlined .mdc-text-field__label--float-above ~ .mdc-text-field__idle-outline {
    opacity: 0; }
  .mdc-text-field--outlined.mdc-text-field--focused .mdc-text-field__outline,
  .mdc-text-field--outlined .mdc-text-field__label--float-above ~ .mdc-text-field__outline {
    opacity: 1; }
  .mdc-text-field--outlined.mdc-text-field--focused .mdc-text-field__outline-path {
    /* @alternate */
    stroke: #6200ee;
    stroke: var(--mdc-theme-primary, #6200ee);
    stroke-width: 2px; }
  .mdc-text-field--outlined:not(.mdc-text-field--invalid).mdc-text-field--focused .mdc-text-field__label {
    /* @alternate */
    color: #6200ee;
    color: var(--mdc-theme-primary, #6200ee); }
  .mdc-text-field--outlined:not(.mdc-text-field--invalid) .mdc-text-field__input:hover ~ .mdc-text-field__outline .mdc-text-field__outline-path {
    stroke: rgba(0, 0, 0, 0.87); }
  .mdc-text-field--outlined.mdc-text-field--disabled {
    color: rgba(0, 0, 0, 0.38); }
    .mdc-text-field--outlined.mdc-text-field--disabled .mdc-text-field__input {
      border-bottom: none; }
    .mdc-text-field--outlined.mdc-text-field--disabled .mdc-text-field__outline-path {
      stroke: rgba(0, 0, 0, 0.06);
      stroke-width: 1px; }
    .mdc-text-field--outlined.mdc-text-field--disabled .mdc-text-field__idle-outline {
      border-color: rgba(0, 0, 0, 0.06); }
    .mdc-text-field--outlined.mdc-text-field--disabled .mdc-text-field__label {
      bottom: 20px; }
    .mdc-text-field--outlined.mdc-text-field--disabled .mdc-text-field__icon {
      color: rgba(0, 0, 0, 0.3); }
  .mdc-text-field--outlined.mdc-text-field--dense {
    height: 48px; }
    .mdc-text-field--outlined.mdc-text-field--dense .mdc-text-field__input {
      padding: 12px 12px 7px; }
    .mdc-text-field--outlined.mdc-text-field--dense .mdc-text-field__label {
      bottom: 18px; }
      .mdc-text-field--outlined.mdc-text-field--dense .mdc-text-field__label--float-above {
        transform: translateY(calc(-122% - 2px)) scale(0.923); }
        .mdc-text-field--outlined.mdc-text-field--dense .mdc-text-field__label--float-above.mdc-text-field__label--shake {
          animation: invalid-shake-float-above-outlined-dense 250ms 1; }
    .mdc-text-field--outlined.mdc-text-field--dense .mdc-text-field__icon {
      top: 12px; }

.mdc-text-field--box {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  border-radius: 4px 4px 0 0;
  display: inline-flex;
  position: relative;
  height: 56px;
  margin-top: 16px;
  background-color: rgba(0, 0, 0, 0.04);
  overflow: hidden; }
  .mdc-text-field--box::before, .mdc-text-field--box::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-text-field--box::before {
    transition: opacity 15ms linear; }
  .mdc-text-field--box.mdc-ripple-upgraded::after {
    top: 0;
    left: 0;
    transform: scale(0);
    transform-origin: center center; }
  .mdc-text-field--box.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    left: var(--mdc-ripple-left, 0); }
  .mdc-text-field--box.mdc-ripple-upgraded--foreground-activation::after {
    animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
  .mdc-text-field--box.mdc-ripple-upgraded--foreground-deactivation::after {
    animation: 150ms mdc-ripple-fg-opacity-out;
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-text-field--box::before, .mdc-text-field--box::after {
    /* @alternate */
    background-color: rgba(0, 0, 0, 0.87); }
    @supports not (-ms-ime-align: auto) {
      .mdc-text-field--box::before, .mdc-text-field--box::after {
        background-color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)); } }
  .mdc-text-field--box:hover::before {
    opacity: 0.04; }
  .mdc-text-field--box:not(.mdc-ripple-upgraded):focus::before, .mdc-text-field--box:not(.mdc-ripple-upgraded):focus-within::before, .mdc-text-field--box.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-text-field--box:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-text-field--box:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.16; }
  .mdc-text-field--box.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: $opacity; }
  .mdc-text-field--box::before, .mdc-text-field--box::after {
    top: calc(50% - 100%);
    left: calc(50% - 100%);
    width: 200%;
    height: 200%; }
  .mdc-text-field--box.mdc-ripple-upgraded::before {
    top: calc(50% - 100%);
    left: calc(50% - 100%);
    width: 200%;
    height: 200%;
    transform: scale(var(--mdc-ripple-fg-scale, 0)); }
  .mdc-text-field--box.mdc-ripple-upgraded--unbounded::before {
    top: var(--mdc-ripple-top, calc(50% - 50%));
    left: var(--mdc-ripple-left, calc(50% - 50%));
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%);
    transform: scale(var(--mdc-ripple-fg-scale, 0)); }
  .mdc-text-field--box.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-text-field--theme-dark.mdc-text-field--box,
  .mdc-theme--dark .mdc-text-field--box {
    background-color: rgba(255, 255, 255, 0.1); }
  .mdc-text-field--theme-dark.mdc-text-field--box::before, .mdc-text-field--theme-dark.mdc-text-field--box::after,
  .mdc-theme--dark .mdc-text-field--box::before,
  .mdc-theme--dark .mdc-text-field--box::after {
    /* @alternate */
    background-color: white; }
    @supports not (-ms-ime-align: auto) {
      .mdc-text-field--theme-dark.mdc-text-field--box::before, .mdc-text-field--theme-dark.mdc-text-field--box::after,
      .mdc-theme--dark .mdc-text-field--box::before,
      .mdc-theme--dark .mdc-text-field--box::after {
        background-color: var(--mdc-theme-text-primary-on-dark, white); } }
  .mdc-text-field--theme-dark.mdc-text-field--box:hover::before,
  .mdc-theme--dark .mdc-text-field--box:hover::before {
    opacity: 0.08; }
  .mdc-text-field--theme-dark.mdc-text-field--box:not(.mdc-ripple-upgraded):focus::before, .mdc-text-field--theme-dark.mdc-text-field--box:not(.mdc-ripple-upgraded):focus-within::before, .mdc-text-field--theme-dark.mdc-text-field--box.mdc-ripple-upgraded--background-focused::before,
  .mdc-theme--dark .mdc-text-field--box:not(.mdc-ripple-upgraded):focus::before,
  .mdc-theme--dark .mdc-text-field--box:not(.mdc-ripple-upgraded):focus-within::before,
  .mdc-theme--dark .mdc-text-field--box.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.24; }
  .mdc-text-field--theme-dark.mdc-text-field--box:not(.mdc-ripple-upgraded)::after,
  .mdc-theme--dark .mdc-text-field--box:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-text-field--theme-dark.mdc-text-field--box:not(.mdc-ripple-upgraded):active::after,
  .mdc-theme--dark .mdc-text-field--box:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.32; }
  .mdc-text-field--theme-dark.mdc-text-field--box.mdc-ripple-upgraded,
  .mdc-theme--dark .mdc-text-field--box.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: $opacity; }
  .mdc-text-field--box .mdc-text-field__input {
    align-self: flex-end;
    box-sizing: border-box;
    height: 100%;
    padding: 20px 16px 0; }
  .mdc-text-field--box .mdc-text-field__label {
    left: 16px;
    right: initial;
    position: absolute;
    bottom: 20px;
    width: calc(100% - 48px);
    color: rgba(0, 0, 0, 0.6);
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    overflow: hidden;
    will-change: transform; }
    [dir="rtl"] .mdc-text-field--box .mdc-text-field__label, .mdc-text-field--box .mdc-text-field__label[dir="rtl"] {
      left: initial;
      right: 16px; }
    .mdc-text-field--theme-dark .mdc-text-field--box .mdc-text-field__label,
    .mdc-theme--dark .mdc-text-field--box .mdc-text-field__label {
      /* @alternate */
      color: rgba(255, 255, 255, 0.7);
      color: var(--mdc-theme-text-secondary-on-dark, rgba(255, 255, 255, 0.7)); }
    .mdc-text-field--box .mdc-text-field__label--float-above {
      transform: translateY(-50%) scale(0.75, 0.75); }
  .mdc-text-field--box .mdc-text-field__label--float-above.mdc-text-field__label--shake {
    animation: invalid-shake-float-above-box 250ms 1; }
  .mdc-text-field--box.mdc-text-field--disabled {
    color: rgba(0, 0, 0, 0.38);
    border-bottom: none;
    background-color: rgba(0, 0, 0, 0.02); }
    .mdc-text-field--theme-dark.mdc-text-field--box.mdc-text-field--disabled,
    .mdc-theme--dark .mdc-text-field--box.mdc-text-field--disabled {
      background-color: #303030;
      color: rgba(255, 255, 255, 0.3);
      border-bottom: none; }
    .mdc-text-field--box.mdc-text-field--disabled .mdc-text-field__label {
      bottom: 20px; }
    .mdc-text-field--box.mdc-text-field--disabled .mdc-text-field__icon {
      color: rgba(0, 0, 0, 0.3); }
      .mdc-text-field--box.mdc-text-field--disabled .mdc-text-field__icon--theme-dark,
      .mdc-theme--dark .mdc-text-field--box.mdc-text-field--disabled .mdc-text-field__icon {
        color: rgba(255, 255, 255, 0.3); }
  .mdc-text-field--box.mdc-text-field--dense .mdc-text-field__input {
    padding: 12px 12px 0; }
  .mdc-text-field--box.mdc-text-field--dense .mdc-text-field__label {
    bottom: 20px; }
    .mdc-text-field--box.mdc-text-field--dense .mdc-text-field__label--float-above {
      transform: translateY(calc(-75% - 2px)) scale(0.923, 0.923); }

.mdc-text-field--with-leading-icon .mdc-text-field__input {
  padding-left: 48px;
  padding-right: 15px; }
  [dir="rtl"] .mdc-text-field--with-leading-icon .mdc-text-field__input, .mdc-text-field--with-leading-icon .mdc-text-field__input[dir="rtl"] {
    padding-left: 15px;
    padding-right: 48px; }

.mdc-text-field--with-leading-icon .mdc-text-field__icon {
  left: 15px;
  right: initial; }
  [dir="rtl"] .mdc-text-field--with-leading-icon .mdc-text-field__icon, .mdc-text-field--with-leading-icon .mdc-text-field__icon[dir="rtl"] {
    left: initial;
    right: 15px; }

.mdc-text-field--with-leading-icon .mdc-text-field__label {
  left: 48px;
  right: initial; }
  [dir="rtl"] .mdc-text-field--with-leading-icon .mdc-text-field__label, .mdc-text-field--with-leading-icon .mdc-text-field__label[dir="rtl"] {
    left: initial;
    right: 48px; }

.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-text-field__label--float-above {
  transform: scale(0.75) translateX(-36%) translateY(-170%); }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-text-field__label--float-above, .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-text-field__label--float-above[dir="rtl"] {
    transform: scale(0.75) translateX(36%) translateY(-170%); }

.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-text-field__label--float-above {
  transform: translateX(-25%) translateY(-145%) scale(0.923); }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-text-field__label--float-above, .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-text-field__label--float-above[dir="rtl"] {
    transform: translateX(25%) translateY(-145%) scale(0.923); }

.mdc-text-field--with-trailing-icon .mdc-text-field__input {
  padding-left: 15px;
  padding-right: 48px; }
  [dir="rtl"] .mdc-text-field--with-trailing-icon .mdc-text-field__input, .mdc-text-field--with-trailing-icon .mdc-text-field__input[dir="rtl"] {
    padding-left: 48px;
    padding-right: 15px; }

.mdc-text-field--with-trailing-icon .mdc-text-field__icon {
  left: initial;
  right: 15px; }
  [dir="rtl"] .mdc-text-field--with-trailing-icon .mdc-text-field__icon, .mdc-text-field--with-trailing-icon .mdc-text-field__icon[dir="rtl"] {
    left: 15px;
    right: initial; }

.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon,
.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon {
  bottom: 16px;
  transform: scale(0.8); }

.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__input {
  padding-left: 38px;
  padding-right: 12px; }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__input, .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__input[dir="rtl"] {
    padding-left: 12px;
    padding-right: 38px; }

.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon {
  left: 12px;
  right: initial; }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon, .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon[dir="rtl"] {
    left: initial;
    right: 12px; }

.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__label {
  left: 38px;
  right: initial; }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__label, .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__label[dir="rtl"] {
    left: initial;
    right: 38px; }

.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input {
  padding-left: 12px;
  padding-right: 38px; }
  [dir="rtl"] .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input, .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input[dir="rtl"] {
    padding-left: 38px;
    padding-right: 12px; }

.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon {
  left: initial;
  right: 12px; }
  [dir="rtl"] .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon, .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon[dir="rtl"] {
    left: 12px;
    right: initial; }

.mdc-text-field--upgraded:not(.mdc-text-field--fullwidth):not(.mdc-text-field--box) {
  display: inline-flex;
  position: relative;
  align-items: flex-end;
  box-sizing: border-box;
  margin-top: 16px; }
  .mdc-text-field--upgraded:not(.mdc-text-field--fullwidth):not(.mdc-text-field--box):not(.mdc-text-field--textarea):not(.mdc-text-field--outlined) {
    height: 48px; }
  .mdc-text-field--upgraded:not(.mdc-text-field--fullwidth):not(.mdc-text-field--box) .mdc-text-field__label {
    pointer-events: none; }

.mdc-text-field--invalid .mdc-text-field__label {
  color: #d50000; }

.mdc-text-field--invalid .mdc-text-field__input {
  border-color: #d50000; }

.mdc-text-field--invalid .mdc-text-field__bottom-line {
  background-color: #d50000; }

.mdc-text-field--invalid .mdc-text-field__idle-outline {
  border-color: #d50000; }

.mdc-text-field--invalid .mdc-text-field__input:hover ~ .mdc-text-field__idle-outline {
  border-color: #d50000; }

.mdc-text-field--invalid .mdc-text-field__outline .mdc-text-field__outline-path {
  stroke: #d50000; }

.mdc-text-field--invalid + .mdc-text-field-helper-text--validation-msg {
  opacity: 1;
  color: #d50000; }

.mdc-text-field--theme-dark.mdc-text-field--invalid + .mdc-text-field-helper-text--validation-msg,
.mdc-theme--dark .mdc-text-field--invalid + .mdc-text-field-helper-text--validation-msg {
  color: #ff6e6e; }

.mdc-text-field--invalid.mdc-text-field--textarea {
  border-color: #d50000; }

.mdc-text-field--dense {
  margin-top: 12px;
  margin-bottom: 4px;
  font-size: .813rem; }
  .mdc-text-field--dense .mdc-text-field__label--float-above {
    transform: translateY(calc(-100% - 2px)) scale(0.923, 0.923); }

.mdc-text-field--disabled {
  pointer-events: none; }
  .mdc-text-field--disabled .mdc-text-field__input {
    border-bottom: 1px dotted rgba(35, 31, 32, 0.26); }
    .mdc-text-field--theme-dark.mdc-text-field--disabled .mdc-text-field__input,
    .mdc-theme--dark .mdc-text-field--disabled .mdc-text-field__input {
      border-bottom: 1px dotted rgba(255, 255, 255, 0.3); }
  .mdc-text-field--disabled .mdc-text-field__input,
  .mdc-text-field--disabled .mdc-text-field__label,
  .mdc-text-field--disabled + .mdc-text-field-helper-text {
    /* @alternate */
    color: rgba(0, 0, 0, 0.38);
    color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38)); }
  .mdc-text-field--theme-dark .mdc-text-field--disabled .mdc-text-field__input,
  .mdc-theme--dark .mdc-text-field--disabled .mdc-text-field__input, .mdc-text-field--theme-dark
  .mdc-text-field--disabled .mdc-text-field__label,
  .mdc-theme--dark
  .mdc-text-field--disabled .mdc-text-field__label {
    /* @alternate */
    color: rgba(255, 255, 255, 0.5);
    color: var(--mdc-theme-text-disabled-on-dark, rgba(255, 255, 255, 0.5)); }
  .mdc-text-field--theme-dark.mdc-text-field--disabled + .mdc-text-field-helper-text,
  .mdc-theme--dark .mdc-text-field--disabled + .mdc-text-field-helper-text {
    /* @alternate */
    color: rgba(255, 255, 255, 0.5);
    color: var(--mdc-theme-text-disabled-on-dark, rgba(255, 255, 255, 0.5)); }
  .mdc-text-field--disabled .mdc-text-field__label {
    bottom: 8px;
    cursor: default; }

.mdc-text-field__input:required + .mdc-text-field__label::after {
  margin-left: 1px;
  content: "*"; }

.mdc-text-field--focused .mdc-text-field__input:required + .mdc-text-field__label::after {
  color: #d50000; }
  .mdc-text-field--theme-dark.mdc-text-field--focused .mdc-text-field__input:required + .mdc-text-field__label::after,
  .mdc-theme--dark .mdc-text-field--focused .mdc-text-field__input:required + .mdc-text-field__label::after {
    color: #ff6e6e; }

.mdc-text-field--focused + .mdc-text-field-helper-text:not(.mdc-text-field-helper-text--validation-msg) {
  opacity: 1; }

.mdc-text-field--textarea {
  border-radius: 4px;
  display: flex;
  height: initial;
  transition: none;
  border: 1px solid rgba(0, 0, 0, 0.73);
  overflow: hidden; }
  .mdc-text-field--textarea .mdc-text-field__label {
    border-radius: 4px 4px 0 0; }
  .mdc-text-field--textarea .mdc-text-field__input {
    border-radius: 2px; }
  .mdc-text-field--theme-dark .mdc-text-field--textarea,
  .mdc-theme--dark .mdc-text-field--textarea {
    border-color: white; }
  .mdc-text-field--textarea .mdc-text-field__input {
    padding: 16px;
    padding-top: 32px;
    border: 1px solid transparent; }
    .mdc-text-field--textarea .mdc-text-field__input:focus {
      /* @alternate */
      border-color: #6200ee;
      border-color: var(--mdc-theme-primary, #6200ee); }
    .mdc-text-field--textarea .mdc-text-field__input:invalid:not(:focus) {
      border-color: #d50000; }
    .mdc-text-field--theme-dark .mdc-text-field--textarea .mdc-text-field__input:hover,
    .mdc-theme--dark .mdc-text-field--textarea .mdc-text-field__input:hover {
      border-bottom-color: transparent; }
    .mdc-text-field--theme-dark .mdc-text-field--textarea .mdc-text-field__input:focus,
    .mdc-theme--dark .mdc-text-field--textarea .mdc-text-field__input:focus {
      /* @alternate */
      border-color: #018786;
      border-color: var(--mdc-theme-secondary, #018786); }
    .mdc-text-field--theme-dark .mdc-text-field--textarea .mdc-text-field__input:invalid:not(:focus),
    .mdc-theme--dark .mdc-text-field--textarea .mdc-text-field__input:invalid:not(:focus) {
      border-color: #ff6e6e; }
  .mdc-text-field--textarea .mdc-text-field__label {
    left: 1px;
    right: initial;
    top: 18px;
    bottom: auto;
    padding: 8px 16px;
    background-color: white; }
    [dir="rtl"] .mdc-text-field--textarea .mdc-text-field__label, .mdc-text-field--textarea .mdc-text-field__label[dir="rtl"] {
      left: initial;
      right: 1px; }
    .mdc-text-field--theme-dark .mdc-text-field--textarea .mdc-text-field__label,
    .mdc-theme--dark .mdc-text-field--textarea .mdc-text-field__label {
      background-color: #303030; }
    .mdc-text-field--textarea .mdc-text-field__label--float-above {
      transform: translateY(-50%) scale(0.923, 0.923); }
  .mdc-text-field--textarea.mdc-text-field--disabled {
    border-style: solid;
    border-color: rgba(35, 31, 32, 0.26);
    background-color: #f9f9f9; }
    .mdc-text-field--theme-dark .mdc-text-field--textarea.mdc-text-field--disabled,
    .mdc-theme--dark .mdc-text-field--textarea.mdc-text-field--disabled {
      border-color: rgba(255, 255, 255, 0.3);
      background-color: #2f2f2f; }
    .mdc-text-field--textarea.mdc-text-field--disabled .mdc-text-field__label {
      background-color: #f9f9f9; }
      .mdc-text-field--theme-dark .mdc-text-field--textarea.mdc-text-field--disabled .mdc-text-field__label,
      .mdc-theme--dark .mdc-text-field--textarea.mdc-text-field--disabled .mdc-text-field__label {
        background-color: #2f2f2f; }
  .mdc-text-field--textarea:not(.mdc-text-field--upgraded) .mdc-text-field__input {
    padding-top: 16px; }

.mdc-text-field--textarea.mdc-text-field--focused {
  /* @alternate */
  border-color: #6200ee;
  border-color: var(--mdc-theme-primary, #6200ee); }

.mdc-text-field--fullwidth {
  width: 100%; }
  .mdc-text-field--fullwidth:not(.mdc-text-field--textarea) {
    display: block;
    box-sizing: border-box;
    height: 56px;
    margin: 0;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    outline: none; }
    .mdc-text-field--fullwidth:not(.mdc-text-field--textarea) .mdc-text-field__input {
      width: 100%;
      height: 100%;
      padding: 0;
      resize: none;
      border: none !important; }
  .mdc-text-field--fullwidth--theme-dark,
  .mdc-theme--dark .mdc-text-field--fullwidth {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12); }

.mdc-text-field:not(.mdc-text-field--upgraded) .mdc-text-field__input {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
  .mdc-text-field:not(.mdc-text-field--upgraded) .mdc-text-field__input:focus {
    /* @alternate */
    border-color: #6200ee;
    border-color: var(--mdc-theme-primary, #6200ee); }
  .mdc-text-field:not(.mdc-text-field--upgraded) .mdc-text-field__input:disabled {
    color: rgba(0, 0, 0, 0.38);
    border-bottom-style: dotted; }
  .mdc-text-field:not(.mdc-text-field--upgraded) .mdc-text-field__input:invalid:not(:focus) {
    border-color: #d50000; }

.mdc-text-field--theme-dark:not(.mdc-text-field--upgraded) .mdc-text-field__input:not(:focus),
.mdc-theme--dark .mdc-text-field:not(.mdc-text-field--upgraded) .mdc-text-field__input:not(:focus) {
  border-color: rgba(255, 255, 255, 0.5); }

.mdc-text-field--theme-dark:not(.mdc-text-field--upgraded) .mdc-text-field__input:disabled,
.mdc-theme--dark .mdc-text-field:not(.mdc-text-field--upgraded) .mdc-text-field__input:disabled {
  color: rgba(255, 255, 255, 0.3);
  border-color: rgba(255, 255, 255, 0.3);
  background-color: #2f2f2f; }

.mdc-text-field--theme-dark:not(.mdc-text-field--upgraded) .mdc-text-field__input:invalid:not(:focus),
.mdc-theme--dark .mdc-text-field:not(.mdc-text-field--upgraded) .mdc-text-field__input:invalid:not(:focus) {
  border-color: #ff6e6e; }

.mdc-text-field--outlined:not(.mdc-text-field--upgraded) {
  height: 56px; }
  .mdc-text-field--outlined:not(.mdc-text-field--upgraded) .mdc-text-field__input {
    border-radius: 4px;
    height: 100%;
    padding: 0 0 0 12px;
    border: 1px solid rgba(0, 0, 0, 0.12); }
    .mdc-text-field--outlined:not(.mdc-text-field--upgraded) .mdc-text-field__input:hover {
      border-color: rgba(0, 0, 0, 0.87); }
    .mdc-text-field--outlined:not(.mdc-text-field--upgraded) .mdc-text-field__input:focus {
      /* @alternate */
      border-color: #6200ee;
      border-color: var(--mdc-theme-primary, #6200ee); }

.mdc-text-field--box:not(.mdc-text-field--upgraded) {
  height: 56px; }
  .mdc-text-field--box:not(.mdc-text-field--upgraded)::before, .mdc-text-field--box:not(.mdc-text-field--upgraded)::after {
    border-radius: 0; }
  .mdc-text-field--box:not(.mdc-text-field--upgraded) .mdc-text-field__input {
    padding-top: 0; }

.mdc-text-field--dense + .mdc-text-field-helper-text {
  margin-bottom: 4px; }

.mdc-text-field--box + .mdc-text-field-helper-text {
  margin-right: 16px;
  margin-left: 16px; }

.mdc-form-field > .mdc-text-field + label {
  align-self: flex-start; }

.vc-theme-input {
  margin-top: 10px; }
  .vc-theme-input .vc-theme-input__text-field-container {
    margin-top: 6px;
    width: 67%; }

.theme-create-button {
  margin-top: 15px; }

.mdc-button.mdc-button--raised.vc-button__close-theme-input {
  background-color: #FF0000;
  margin-left: 15px; }

.mdc-button.mdc-button--raised.vc-button__delete-theme {
  background-color: #FF0000; }

.mdc-button.mdc-button--raised .mdc-button__icon {
  margin-right: 0;
  height: 36px;
  width: 36px;
  font-size: 24px; }

.vc-button__create-theme {
  margin-left: 10px; }

.vc-actions--delete-theme {
  position: relative; }
  .vc-actions--delete-theme .vc-themes__confirm-delete-container {
    position: absolute;
    padding: 10px;
    right: 0;
    top: calc(100% + 15px);
    width: 250px;
    background-color: #DEDEDE;
    z-index: 1;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }
    .vc-actions--delete-theme .vc-themes__confirm-delete-container .vc-themes__confirm-delete-pointer {
      background: #DEDEDE;
      width: 0;
      height: 5px;
      display: inline-block;
      position: absolute;
      right: 34px;
      top: -15px;
      border: 10px solid;
      border-top: 0;
      border-left-color: #FFF;
      border-right-color: #FFF;
      border-bottom-color: #DEDEDE; }
    .vc-actions--delete-theme .vc-themes__confirm-delete-container .vc-themes__confirm-delete-message .vc-themes__confirm-delete-buttons {
      margin-top: 10px; }
      .vc-actions--delete-theme .vc-themes__confirm-delete-container .vc-themes__confirm-delete-message .vc-themes__confirm-delete-buttons button {
        outline: none;
        border: 0;
        background: transparent;
        cursor: pointer;
        border-radius: 3px; }
        .vc-actions--delete-theme .vc-themes__confirm-delete-container .vc-themes__confirm-delete-message .vc-themes__confirm-delete-buttons button.yes {
          color: green; }
          .vc-actions--delete-theme .vc-themes__confirm-delete-container .vc-themes__confirm-delete-message .vc-themes__confirm-delete-buttons button.yes:hover {
            color: #FFFFFF;
            background-color: green; }
        .vc-actions--delete-theme .vc-themes__confirm-delete-container .vc-themes__confirm-delete-message .vc-themes__confirm-delete-buttons button.no {
          color: red; }
          .vc-actions--delete-theme .vc-themes__confirm-delete-container .vc-themes__confirm-delete-message .vc-themes__confirm-delete-buttons button.no:hover {
            color: #FFFFFF;
            background-color: red; }
