/* TODO(sgomes): Figure out what to do about desktop font sizes. */
/* TODO(sgomes): Figure out what to do about i18n and i18n font sizes. */
/**
 * Creates a rule that will be applied when an MDC-Web component is within the context of an RTL layout.
 *
 * Usage Example:
 * ```scss
 * .mdc-foo {
 *   position: absolute;
 *   left: 0;
 *
 *   @include mdc-rtl {
 *     left: auto;
 *     right: 0;
 *   }
 *
 *   &__bar {
 *     margin-left: 4px;
 *     @include mdc-rtl(".mdc-foo") {
 *       margin-left: auto;
 *       margin-right: 4px;
 *     }
 *   }
 * }
 *
 * .mdc-foo--mod {
 *   padding-left: 4px;
 *
 *   @include mdc-rtl {
 *     padding-left: auto;
 *     padding-right: 4px;
 *   }
 * }
 * ```
 *
 * Note that this works by checking for [dir="rtl"] on an ancestor element. While this will work
 * in most cases, it will in some cases lead to false negatives, e.g.
 *
 * ```html
 * <html dir="rtl">
 *   <!-- ... -->
 *   <div dir="ltr">
 *     <div class="mdc-foo">Styled incorrectly as RTL!</div>
 *   </div>
 * </html>
 * ```
 *
 * In the future, selectors such as :dir (http://mdn.io/:dir) will help us mitigate this.
 */
/**
 * Takes a base box-model property - e.g. margin / border / padding - along with a default
 * direction and value, and emits rules which apply the value to the
 * "<base-property>-<default-direction>" property by default, but flips the direction
 * when within an RTL context.
 *
 * For example:
 *
 * ```scss
 * .mdc-foo {
 *   @include mdc-rtl-reflexive-box(margin, left, 8px);
 * }
 * ```
 * is equivalent to:
 *
 * ```scss
 * .mdc-foo {
 *   margin-left: 8px;
 *
 *   @include mdc-rtl {
 *     margin-right: 8px;
 *     margin-left: 0;
 *   }
 * }
 * ```
 * whereas:
 *
 * ```scss
 * .mdc-foo {
 *   @include mdc-rtl-reflexive-box(margin, right, 8px);
 * }
 * ```
 * is equivalent to:
 *
 * ```scss
 * .mdc-foo {
 *   margin-right: 8px;
 *
 *   @include mdc-rtl {
 *     margin-right: 0;
 *     margin-left: 8px;
 *   }
 * }
 * ```
 *
 * You can also pass a 4th optional $root-selector argument which will be forwarded to `mdc-rtl`,
 * e.g. `@include mdc-rtl-reflexive-box(margin, left, 8px, ".mdc-component")`.
 *
 * Note that this function will always zero out the original value in an RTL context. If you're
 * trying to flip the values, use mdc-rtl-reflexive-property().
 */
/**
 * Takes a base property and emits rules that assign <base-property>-left to <left-value> and
 * <base-property>-right to <right-value> in a LTR context, and vice versa in a RTL context.
 * For example:
 *
 * ```scss
 * .mdc-foo {
 *   @include mdc-rtl-reflexive-property(margin, auto, 12px);
 * }
 * ```
 * is equivalent to:
 *
 * ```scss
 * .mdc-foo {
 *   margin-left: auto;
 *   margin-right: 12px;
 *
 *   @include mdc-rtl {
 *     margin-left: 12px;
 *     margin-right: auto;
 *   }
 * }
 * ```
 *
 * A 4th optional $root-selector argument can be given, which will be passed to `mdc-rtl`.
 */
/**
 * Takes an argument specifying a horizontal position property (either "left" or "right") as well
 * as a value, and applies that value to the specified position in a LTR context, and flips it in a
 * RTL context. For example:
 *
 * ```scss
 * .mdc-foo {
 *   @include mdc-rtl-reflexive-position(left, 0);
 *   position: absolute;
 * }
 * ```
 * is equivalent to:
 *
 * ```scss
 *  .mdc-foo {
 *    position: absolute;
 *    left: 0;
 *    right: initial;
 *
 *    @include mdc-rtl {
 *      right: 0;
 *      left: initial;
 *    }
 *  }
 * ```
 * An optional third $root-selector argument may also be given, which is passed to `mdc-rtl`.
 */
@keyframes mdc-slider-emphasize {
  0% {
    animation-timing-function: ease-out; }
  50% {
    animation-timing-function: ease-in;
    transform: scale(0.85); }
  100% {
    transform: scale(0.571); } }

.mdc-slider {
  position: relative;
  width: 100%;
  height: 48px;
  cursor: pointer;
  touch-action: pan-x;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  .mdc-slider:not(.mdc-slider--disabled) .mdc-slider__track {
    /* @alternate */
    background-color: #018786;
    background-color: var(--mdc-theme-secondary, #018786); }
  .mdc-slider:not(.mdc-slider--disabled) .mdc-slider__track-container {
    background-color: rgba(1, 135, 134, 0.26); }
  .mdc-slider:not(.mdc-slider--disabled) .mdc-slider__track-marker::after,
  .mdc-slider:not(.mdc-slider--disabled) .mdc-slider__track-marker-container::after {
    /* @alternate */
    background-color: #018786;
    background-color: var(--mdc-theme-secondary, #018786); }
  .mdc-slider:not(.mdc-slider--disabled) .mdc-slider__thumb {
    /* @alternate */
    fill: #018786;
    fill: var(--mdc-theme-secondary, #018786);
    /* @alternate */
    stroke: #018786;
    stroke: var(--mdc-theme-secondary, #018786); }
  .mdc-slider:not(.mdc-slider--disabled) .mdc-slider__focus-ring {
    /* @alternate */
    background-color: #018786;
    background-color: var(--mdc-theme-secondary, #018786); }
  .mdc-slider:not(.mdc-slider--disabled) .mdc-slider__pin {
    /* @alternate */
    background-color: #018786;
    background-color: var(--mdc-theme-secondary, #018786); }
  .mdc-slider:not(.mdc-slider--disabled) .mdc-slider__pin {
    /* @alternate */
    color: white;
    color: var(--mdc-theme-text-primary-on-dark, white); }
  .mdc-slider--disabled .mdc-slider__track {
    background-color: #9a9a9a; }
  .mdc-slider--disabled .mdc-slider__track-container {
    background-color: rgba(154, 154, 154, 0.26); }
  .mdc-slider--disabled .mdc-slider__track-marker::after,
  .mdc-slider--disabled .mdc-slider__track-marker-container::after {
    background-color: #9a9a9a; }
  .mdc-slider--disabled .mdc-slider__thumb {
    fill: #9a9a9a;
    stroke: #9a9a9a; }
  .mdc-slider--disabled .mdc-slider__thumb {
    /* @alternate */
    stroke: white;
    stroke: var(--mdc-slider-bg-color-behind-component, white); }
  .mdc-slider--theme-dark.mdc-slider--disabled .mdc-slider__track,
  .mdc-theme--dark .mdc-slider--disabled .mdc-slider__track {
    background-color: #787878; }
  .mdc-slider--theme-dark.mdc-slider--disabled .mdc-slider__track-container,
  .mdc-theme--dark .mdc-slider--disabled .mdc-slider__track-container {
    background-color: rgba(120, 120, 120, 0.26); }
  .mdc-slider--theme-dark.mdc-slider--disabled .mdc-slider__track-marker::after,
  .mdc-slider--theme-dark.mdc-slider--disabled .mdc-slider__track-marker-container::after,
  .mdc-theme--dark .mdc-slider--disabled .mdc-slider__track-marker::after,
  .mdc-theme--dark .mdc-slider--disabled .mdc-slider__track-marker-container::after {
    background-color: #787878; }
  .mdc-slider--theme-dark.mdc-slider--disabled .mdc-slider__thumb,
  .mdc-theme--dark .mdc-slider--disabled .mdc-slider__thumb {
    fill: #787878;
    stroke: #787878; }
  .mdc-slider--theme-dark.mdc-slider--disabled .mdc-slider__thumb,
  .mdc-theme--dark .mdc-slider--disabled .mdc-slider__thumb {
    /* @alternate */
    stroke: #333;
    stroke: var(--mdc-slider-bg-color-behind-component, #333); }
  .mdc-slider:focus {
    outline: none; }
  .mdc-slider__track-container {
    position: absolute;
    top: 50%;
    width: 100%;
    height: 2px;
    overflow: hidden; }
  .mdc-slider__track {
    position: absolute;
    width: 100%;
    height: 100%;
    transform-origin: left top;
    will-change: transform; }
    [dir="rtl"] .mdc-slider .mdc-slider__track,
    .mdc-slider[dir="rtl"] .mdc-slider__track {
      transform-origin: right top; }
  .mdc-slider__track-marker-container {
    display: flex;
    margin-right: 0;
    margin-left: -1px;
    visibility: hidden; }
    [dir="rtl"] .mdc-slider .mdc-slider__track-marker-container,
    .mdc-slider[dir="rtl"] .mdc-slider__track-marker-container {
      margin-right: -1px;
      margin-left: 0; }
    .mdc-slider__track-marker-container::after {
      display: block;
      width: 2px;
      height: 2px;
      content: ""; }
  .mdc-slider__track-marker {
    flex: 1; }
    .mdc-slider__track-marker::after {
      display: block;
      width: 2px;
      height: 2px;
      content: ""; }
    .mdc-slider__track-marker:first-child::after {
      width: 3px; }
  .mdc-slider__thumb-container {
    position: absolute;
    top: 15px;
    left: 0;
    width: 21px;
    height: 100%;
    user-select: none;
    will-change: transform; }
  .mdc-slider__thumb {
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(0.571);
    transition: transform 100ms ease-out, fill 100ms ease-out, stroke 100ms ease-out;
    stroke-width: 3.5; }
  .mdc-slider__focus-ring {
    width: 21px;
    height: 21px;
    transition: transform 266.67ms ease-out, opacity 266.67ms ease-out, background-color 266.67ms ease-out;
    border-radius: 50%;
    opacity: 0; }
  .mdc-slider__pin {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    margin-top: -2px;
    margin-left: -2px;
    transform: rotate(-45deg) scale(0) translate(0, 0);
    transition: transform 100ms ease-out;
    border-radius: 50% 50% 50% 0%;
    /**
     * Ensuring that the pin is higher than the thumb in the stacking order
     * removes some rendering jank observed in Chrome.
     */
    z-index: 1; }
  .mdc-slider__pin-value-marker {
    font-family: "Open Sans", sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 0.75rem;
    line-height: 1.25rem;
    font-weight: 400;
    letter-spacing: 0.08em;
    text-decoration: inherit;
    text-transform: inherit;
    transform: rotate(45deg); }

.mdc-slider--active .mdc-slider__thumb {
  transform: scale3d(1, 1, 1); }

.mdc-slider--focus .mdc-slider__thumb {
  animation: mdc-slider-emphasize 266.67ms linear; }

.mdc-slider--focus .mdc-slider__focus-ring {
  transform: scale3d(1.55, 1.55, 1.55);
  opacity: .25; }

.mdc-slider--disabled {
  cursor: auto; }

.mdc-slider--in-transit .mdc-slider__thumb {
  transition-delay: 140ms; }

.mdc-slider--in-transit .mdc-slider__thumb-container,
.mdc-slider--in-transit .mdc-slider__track,
.mdc-slider:focus:not(.mdc-slider--active) .mdc-slider__thumb-container,
.mdc-slider:focus:not(.mdc-slider--active) .mdc-slider__track {
  transition: transform 80ms ease; }

.mdc-slider--discrete.mdc-slider--active .mdc-slider__thumb {
  transform: scale(calc(12 / 21)); }

.mdc-slider--discrete.mdc-slider--active .mdc-slider__pin {
  transform: rotate(-45deg) scale(1) translate(19px, -20px); }

.mdc-slider--discrete.mdc-slider--focus .mdc-slider__thumb {
  animation: none; }

.mdc-slider--discrete.mdc-slider--display-markers .mdc-slider__track-marker-container {
  visibility: visible; }

@keyframes mdc-ripple-fg-radius-in {
  from {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1); }
  to {
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); } }

@keyframes mdc-ripple-fg-opacity-in {
  from {
    animation-timing-function: linear;
    opacity: 0; }
  to {
    opacity: var(--mdc-ripple-fg-opacity, 0.16); } }

@keyframes mdc-ripple-fg-opacity-out {
  from {
    animation-timing-function: linear;
    opacity: var(--mdc-ripple-fg-opacity, 0.16); }
  to {
    opacity: 0; } }

.mdc-ripple-surface--test-edge-var-bug {
  --mdc-ripple-surface-test-edge-var: 1px solid #000;
  visibility: hidden; }
  .mdc-ripple-surface--test-edge-var-bug::before {
    border: var(--mdc-ripple-surface-test-edge-var); }

/**
 * Creates a rule that will be applied when an MDC-Web component is within the context of an RTL layout.
 *
 * Usage Example:
 * ```scss
 * .mdc-foo {
 *   position: absolute;
 *   left: 0;
 *
 *   @include mdc-rtl {
 *     left: auto;
 *     right: 0;
 *   }
 *
 *   &__bar {
 *     margin-left: 4px;
 *     @include mdc-rtl(".mdc-foo") {
 *       margin-left: auto;
 *       margin-right: 4px;
 *     }
 *   }
 * }
 *
 * .mdc-foo--mod {
 *   padding-left: 4px;
 *
 *   @include mdc-rtl {
 *     padding-left: auto;
 *     padding-right: 4px;
 *   }
 * }
 * ```
 *
 * Note that this works by checking for [dir="rtl"] on an ancestor element. While this will work
 * in most cases, it will in some cases lead to false negatives, e.g.
 *
 * ```html
 * <html dir="rtl">
 *   <!-- ... -->
 *   <div dir="ltr">
 *     <div class="mdc-foo">Styled incorrectly as RTL!</div>
 *   </div>
 * </html>
 * ```
 *
 * In the future, selectors such as :dir (http://mdn.io/:dir) will help us mitigate this.
 */
/**
 * Takes a base box-model property - e.g. margin / border / padding - along with a default
 * direction and value, and emits rules which apply the value to the
 * "<base-property>-<default-direction>" property by default, but flips the direction
 * when within an RTL context.
 *
 * For example:
 *
 * ```scss
 * .mdc-foo {
 *   @include mdc-rtl-reflexive-box(margin, left, 8px);
 * }
 * ```
 * is equivalent to:
 *
 * ```scss
 * .mdc-foo {
 *   margin-left: 8px;
 *
 *   @include mdc-rtl {
 *     margin-right: 8px;
 *     margin-left: 0;
 *   }
 * }
 * ```
 * whereas:
 *
 * ```scss
 * .mdc-foo {
 *   @include mdc-rtl-reflexive-box(margin, right, 8px);
 * }
 * ```
 * is equivalent to:
 *
 * ```scss
 * .mdc-foo {
 *   margin-right: 8px;
 *
 *   @include mdc-rtl {
 *     margin-right: 0;
 *     margin-left: 8px;
 *   }
 * }
 * ```
 *
 * You can also pass a 4th optional $root-selector argument which will be forwarded to `mdc-rtl`,
 * e.g. `@include mdc-rtl-reflexive-box(margin, left, 8px, ".mdc-component")`.
 *
 * Note that this function will always zero out the original value in an RTL context. If you're
 * trying to flip the values, use mdc-rtl-reflexive-property().
 */
/**
 * Takes a base property and emits rules that assign <base-property>-left to <left-value> and
 * <base-property>-right to <right-value> in a LTR context, and vice versa in a RTL context.
 * For example:
 *
 * ```scss
 * .mdc-foo {
 *   @include mdc-rtl-reflexive-property(margin, auto, 12px);
 * }
 * ```
 * is equivalent to:
 *
 * ```scss
 * .mdc-foo {
 *   margin-left: auto;
 *   margin-right: 12px;
 *
 *   @include mdc-rtl {
 *     margin-left: 12px;
 *     margin-right: auto;
 *   }
 * }
 * ```
 *
 * A 4th optional $root-selector argument can be given, which will be passed to `mdc-rtl`.
 */
/**
 * Takes an argument specifying a horizontal position property (either "left" or "right") as well
 * as a value, and applies that value to the specified position in a LTR context, and flips it in a
 * RTL context. For example:
 *
 * ```scss
 * .mdc-foo {
 *   @include mdc-rtl-reflexive-position(left, 0);
 *   position: absolute;
 * }
 * ```
 * is equivalent to:
 *
 * ```scss
 *  .mdc-foo {
 *    position: absolute;
 *    left: 0;
 *    right: initial;
 *
 *    @include mdc-rtl {
 *      right: 0;
 *      left: initial;
 *    }
 *  }
 * ```
 * An optional third $root-selector argument may also be given, which is passed to `mdc-rtl`.
 */
/* TODO(sgomes): Figure out what to do about desktop font sizes. */
/* TODO(sgomes): Figure out what to do about i18n and i18n font sizes. */
/* TODO(sgomes): Figure out what to do about desktop font sizes. */
/* TODO(sgomes): Figure out what to do about i18n and i18n font sizes. */
/**
 * Creates a rule that will be applied when an MDC-Web component is within the context of an RTL layout.
 *
 * Usage Example:
 * ```scss
 * .mdc-foo {
 *   position: absolute;
 *   left: 0;
 *
 *   @include mdc-rtl {
 *     left: auto;
 *     right: 0;
 *   }
 *
 *   &__bar {
 *     margin-left: 4px;
 *     @include mdc-rtl(".mdc-foo") {
 *       margin-left: auto;
 *       margin-right: 4px;
 *     }
 *   }
 * }
 *
 * .mdc-foo--mod {
 *   padding-left: 4px;
 *
 *   @include mdc-rtl {
 *     padding-left: auto;
 *     padding-right: 4px;
 *   }
 * }
 * ```
 *
 * Note that this works by checking for [dir="rtl"] on an ancestor element. While this will work
 * in most cases, it will in some cases lead to false negatives, e.g.
 *
 * ```html
 * <html dir="rtl">
 *   <!-- ... -->
 *   <div dir="ltr">
 *     <div class="mdc-foo">Styled incorrectly as RTL!</div>
 *   </div>
 * </html>
 * ```
 *
 * In the future, selectors such as :dir (http://mdn.io/:dir) will help us mitigate this.
 */
/**
 * Takes a base box-model property - e.g. margin / border / padding - along with a default
 * direction and value, and emits rules which apply the value to the
 * "<base-property>-<default-direction>" property by default, but flips the direction
 * when within an RTL context.
 *
 * For example:
 *
 * ```scss
 * .mdc-foo {
 *   @include mdc-rtl-reflexive-box(margin, left, 8px);
 * }
 * ```
 * is equivalent to:
 *
 * ```scss
 * .mdc-foo {
 *   margin-left: 8px;
 *
 *   @include mdc-rtl {
 *     margin-right: 8px;
 *     margin-left: 0;
 *   }
 * }
 * ```
 * whereas:
 *
 * ```scss
 * .mdc-foo {
 *   @include mdc-rtl-reflexive-box(margin, right, 8px);
 * }
 * ```
 * is equivalent to:
 *
 * ```scss
 * .mdc-foo {
 *   margin-right: 8px;
 *
 *   @include mdc-rtl {
 *     margin-right: 0;
 *     margin-left: 8px;
 *   }
 * }
 * ```
 *
 * You can also pass a 4th optional $root-selector argument which will be forwarded to `mdc-rtl`,
 * e.g. `@include mdc-rtl-reflexive-box(margin, left, 8px, ".mdc-component")`.
 *
 * Note that this function will always zero out the original value in an RTL context. If you're
 * trying to flip the values, use mdc-rtl-reflexive-property().
 */
/**
 * Takes a base property and emits rules that assign <base-property>-left to <left-value> and
 * <base-property>-right to <right-value> in a LTR context, and vice versa in a RTL context.
 * For example:
 *
 * ```scss
 * .mdc-foo {
 *   @include mdc-rtl-reflexive-property(margin, auto, 12px);
 * }
 * ```
 * is equivalent to:
 *
 * ```scss
 * .mdc-foo {
 *   margin-left: auto;
 *   margin-right: 12px;
 *
 *   @include mdc-rtl {
 *     margin-left: 12px;
 *     margin-right: auto;
 *   }
 * }
 * ```
 *
 * A 4th optional $root-selector argument can be given, which will be passed to `mdc-rtl`.
 */
/**
 * Takes an argument specifying a horizontal position property (either "left" or "right") as well
 * as a value, and applies that value to the specified position in a LTR context, and flips it in a
 * RTL context. For example:
 *
 * ```scss
 * .mdc-foo {
 *   @include mdc-rtl-reflexive-position(left, 0);
 *   position: absolute;
 * }
 * ```
 * is equivalent to:
 *
 * ```scss
 *  .mdc-foo {
 *    position: absolute;
 *    left: 0;
 *    right: initial;
 *
 *    @include mdc-rtl {
 *      right: 0;
 *      left: initial;
 *    }
 *  }
 * ```
 * An optional third $root-selector argument may also be given, which is passed to `mdc-rtl`.
 */
.mdc-list--theme-dark .mdc-list-divider,
.mdc-theme--dark .mdc-list .mdc-list-divider,
.mdc-list-group--theme-dark .mdc-list-divider,
.mdc-theme--dark
.mdc-list-group .mdc-list-divider {
  border-bottom-color: rgba(255, 255, 255, 0.2); }

.mdc-list {
  font-family: "Open Sans", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.04em;
  text-decoration: inherit;
  text-transform: inherit;
  /* @alternate */
  color: rgba(0, 0, 0, 0.87);
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
  margin: 0;
  padding: 8px 0;
  line-height: 1.5rem;
  list-style-type: none; }
  .mdc-list--theme-dark,
  .mdc-theme--dark .mdc-list {
    /* @alternate */
    color: white;
    color: var(--mdc-theme-text-primary-on-dark, white); }
    .mdc-list--theme-dark .mdc-list-item__secondary-text,
    .mdc-theme--dark .mdc-list .mdc-list-item__secondary-text {
      /* @alternate */
      color: rgba(255, 255, 255, 0.7);
      color: var(--mdc-theme-text-secondary-on-dark, rgba(255, 255, 255, 0.7)); }
    .mdc-list--theme-dark .mdc-list-item__graphic,
    .mdc-theme--dark .mdc-list .mdc-list-item__graphic {
      /* @alternate */
      color: rgba(255, 255, 255, 0.5);
      color: var(--mdc-theme-text-icon-on-dark, rgba(255, 255, 255, 0.5)); }
    .mdc-list--theme-dark .mdc-list-item__meta,
    .mdc-theme--dark .mdc-list .mdc-list-item__meta {
      /* @alternate */
      color: rgba(255, 255, 255, 0.5);
      color: var(--mdc-theme-text-hint-on-dark, rgba(255, 255, 255, 0.5)); }

.mdc-list-item__secondary-text {
  /* @alternate */
  color: rgba(0, 0, 0, 0.54);
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54)); }

.mdc-list-item__graphic {
  background-color: transparent; }

.mdc-list-item__graphic {
  /* @alternate */
  color: rgba(0, 0, 0, 0.38);
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38)); }

.mdc-list-item__meta {
  /* @alternate */
  color: rgba(0, 0, 0, 0.38);
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38)); }

.mdc-list--dense {
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: .812rem; }

.mdc-list-item {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 48px;
  padding: 0 16px; }
  .mdc-list-item--selected, .mdc-list-item--activated {
    /* @alternate */
    color: #6200ee;
    color: var(--mdc-theme-primary, #6200ee); }
    .mdc-list-item--selected .mdc-list-item__graphic, .mdc-list-item--activated .mdc-list-item__graphic {
      /* @alternate */
      color: #6200ee;
      color: var(--mdc-theme-primary, #6200ee); }
  .mdc-list-item__graphic {
    margin-left: 0;
    margin-right: 32px;
    width: 24px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center; }
    [dir="rtl"] .mdc-list-item .mdc-list-item__graphic,
    .mdc-list-item[dir="rtl"] .mdc-list-item__graphic {
      margin-left: 32px;
      margin-right: 0; }
  .mdc-list-item__meta {
    margin-left: auto;
    margin-right: 0; }
    [dir="rtl"] .mdc-list-item .mdc-list-item__meta,
    .mdc-list-item[dir="rtl"] .mdc-list-item__meta {
      margin-left: 0;
      margin-right: auto; }
  .mdc-list-item__text {
    display: inline-flex;
    flex-direction: column; }
  .mdc-list-item__secondary-text {
    font-family: "Open Sans", sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
    letter-spacing: 0.04em;
    text-decoration: inherit;
    text-transform: inherit; }
    .mdc-list--dense .mdc-list-item__secondary-text {
      font-size: inherit; }
  .mdc-list--dense .mdc-list-item {
    height: 40px; }
    .mdc-list--dense .mdc-list-item__graphic {
      margin-left: 0;
      margin-right: 36px;
      width: 20px;
      height: 20px; }
      [dir="rtl"] .mdc-list-item .mdc-list--dense .mdc-list-item__graphic,
      .mdc-list-item[dir="rtl"] .mdc-list--dense .mdc-list-item__graphic {
        margin-left: 36px;
        margin-right: 0; }
  .mdc-list--avatar-list .mdc-list-item {
    height: 56px; }
    .mdc-list--avatar-list .mdc-list-item__graphic {
      margin-left: 0;
      margin-right: 16px;
      width: 40px;
      height: 40px;
      border-radius: 50%; }
      [dir="rtl"] .mdc-list-item .mdc-list--avatar-list .mdc-list-item__graphic,
      .mdc-list-item[dir="rtl"] .mdc-list--avatar-list .mdc-list-item__graphic {
        margin-left: 16px;
        margin-right: 0; }
  .mdc-list-item .mdc-list--avatar-list.mdc-list--dense .mdc-list__item {
    height: 48px; }
    .mdc-list-item .mdc-list--avatar-list.mdc-list--dense .mdc-list__item__graphic {
      margin-left: 0;
      margin-right: 20px;
      width: 36px;
      height: 36px; }
      [dir="rtl"] .mdc-list-item .mdc-list-item .mdc-list--avatar-list.mdc-list--dense .mdc-list__item__graphic,
      .mdc-list-item[dir="rtl"] .mdc-list-item .mdc-list--avatar-list.mdc-list--dense .mdc-list__item__graphic {
        margin-left: 20px;
        margin-right: 0; }
  .mdc-list--two-line .mdc-list-item {
    height: 72px; }
  .mdc-list--two-line.mdc-list--dense .mdc-list-item {
    height: 60px; }

a.mdc-list-item {
  color: inherit;
  text-decoration: none; }

.mdc-list-item {
  position: relative;
  overflow: hidden; }
  .mdc-list-item:focus {
    outline: none; }

:not(.mdc-list--non-interactive) > .mdc-list-item {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity; }
  :not(.mdc-list--non-interactive) > .mdc-list-item::before, :not(.mdc-list--non-interactive) > .mdc-list-item::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  :not(.mdc-list--non-interactive) > .mdc-list-item::before {
    transition: opacity 15ms linear; }
  :not(.mdc-list--non-interactive) > .mdc-list-item.mdc-ripple-upgraded::after {
    top: 0;
    left: 0;
    transform: scale(0);
    transform-origin: center center; }
  :not(.mdc-list--non-interactive) > .mdc-list-item.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    left: var(--mdc-ripple-left, 0); }
  :not(.mdc-list--non-interactive) > .mdc-list-item.mdc-ripple-upgraded--foreground-activation::after {
    animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
  :not(.mdc-list--non-interactive) > .mdc-list-item.mdc-ripple-upgraded--foreground-deactivation::after {
    animation: 150ms mdc-ripple-fg-opacity-out;
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  :not(.mdc-list--non-interactive) > .mdc-list-item::before, :not(.mdc-list--non-interactive) > .mdc-list-item::after {
    top: calc(50% - 100%);
    left: calc(50% - 100%);
    width: 200%;
    height: 200%; }
  :not(.mdc-list--non-interactive) > .mdc-list-item.mdc-ripple-upgraded::before {
    top: calc(50% - 100%);
    left: calc(50% - 100%);
    width: 200%;
    height: 200%;
    transform: scale(var(--mdc-ripple-fg-scale, 0)); }
  :not(.mdc-list--non-interactive) > .mdc-list-item.mdc-ripple-upgraded--unbounded::before {
    top: var(--mdc-ripple-top, calc(50% - 50%));
    left: var(--mdc-ripple-left, calc(50% - 50%));
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%);
    transform: scale(var(--mdc-ripple-fg-scale, 0)); }
  :not(.mdc-list--non-interactive) > .mdc-list-item.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  :not(.mdc-list--non-interactive) > .mdc-list-item::before, :not(.mdc-list--non-interactive) > .mdc-list-item::after {
    background-color: black; }
  :not(.mdc-list--non-interactive) > .mdc-list-item:hover::before {
    opacity: 0.04; }
  :not(.mdc-list--non-interactive) > .mdc-list-item:not(.mdc-ripple-upgraded):focus::before, :not(.mdc-list--non-interactive) > .mdc-list-item.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  :not(.mdc-list--non-interactive) > .mdc-list-item:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  :not(.mdc-list--non-interactive) > .mdc-list-item:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.16; }
  :not(.mdc-list--non-interactive) > .mdc-list-item.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: $opacity; }
  :not(.mdc-list--non-interactive) > .mdc-list-item--activated::before {
    opacity: 0.12; }
  :not(.mdc-list--non-interactive) > .mdc-list-item--activated::before, :not(.mdc-list--non-interactive) > .mdc-list-item--activated::after {
    /* @alternate */
    background-color: #6200ee; }
    @supports not (-ms-ime-align: auto) {
      :not(.mdc-list--non-interactive) > .mdc-list-item--activated::before, :not(.mdc-list--non-interactive) > .mdc-list-item--activated::after {
        background-color: var(--mdc-theme-primary, #6200ee); } }
  :not(.mdc-list--non-interactive) > .mdc-list-item--activated:hover::before {
    opacity: 0.16; }
  :not(.mdc-list--non-interactive) > .mdc-list-item--activated:not(.mdc-ripple-upgraded):focus::before, :not(.mdc-list--non-interactive) > .mdc-list-item--activated.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.24; }
  :not(.mdc-list--non-interactive) > .mdc-list-item--activated:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  :not(.mdc-list--non-interactive) > .mdc-list-item--activated:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.28; }
  :not(.mdc-list--non-interactive) > .mdc-list-item--activated.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: $opacity; }
  :not(.mdc-list--non-interactive) > .mdc-list-item--selected::before {
    opacity: 0.04; }
  :not(.mdc-list--non-interactive) > .mdc-list-item--selected::before, :not(.mdc-list--non-interactive) > .mdc-list-item--selected::after {
    /* @alternate */
    background-color: #6200ee; }
    @supports not (-ms-ime-align: auto) {
      :not(.mdc-list--non-interactive) > .mdc-list-item--selected::before, :not(.mdc-list--non-interactive) > .mdc-list-item--selected::after {
        background-color: var(--mdc-theme-primary, #6200ee); } }
  :not(.mdc-list--non-interactive) > .mdc-list-item--selected:hover::before {
    opacity: 0.08; }
  :not(.mdc-list--non-interactive) > .mdc-list-item--selected:not(.mdc-ripple-upgraded):focus::before, :not(.mdc-list--non-interactive) > .mdc-list-item--selected.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.16; }
  :not(.mdc-list--non-interactive) > .mdc-list-item--selected:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  :not(.mdc-list--non-interactive) > .mdc-list-item--selected:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.2; }
  :not(.mdc-list--non-interactive) > .mdc-list-item--selected.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: $opacity; }
  .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item::before, .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item::after,
  .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item::before,
  .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item::after {
    background-color: white; }
  .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item:hover::before,
  .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item:hover::before {
    opacity: 0.08; }
  .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item:not(.mdc-ripple-upgraded):focus::before, .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item.mdc-ripple-upgraded--background-focused::before,
  .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item:not(.mdc-ripple-upgraded):focus::before,
  .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.24; }
  .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item:not(.mdc-ripple-upgraded)::after,
  .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item:not(.mdc-ripple-upgraded):active::after,
  .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.32; }
  .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item.mdc-ripple-upgraded,
  .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: $opacity; }
  .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated::before,
  .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated::before {
    opacity: 0.12; }
  .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated::before, .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated::after,
  .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated::before,
  .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated::after {
    /* @alternate */
    background-color: #6200ee; }
    @supports not (-ms-ime-align: auto) {
      .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated::before, .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated::after,
      .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated::before,
      .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated::after {
        background-color: var(--mdc-theme-primary, #6200ee); } }
  .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated:hover::before,
  .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated:hover::before {
    opacity: 0.16; }
  .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated:not(.mdc-ripple-upgraded):focus::before, .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated.mdc-ripple-upgraded--background-focused::before,
  .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated:not(.mdc-ripple-upgraded):focus::before,
  .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.24; }
  .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated:not(.mdc-ripple-upgraded)::after,
  .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated:not(.mdc-ripple-upgraded):active::after,
  .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.28; }
  .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated.mdc-ripple-upgraded,
  .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--activated.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: $opacity; }
  .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected::before,
  .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected::before {
    opacity: 0.04; }
  .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected::before, .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected::after,
  .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected::before,
  .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected::after {
    /* @alternate */
    background-color: #6200ee; }
    @supports not (-ms-ime-align: auto) {
      .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected::before, .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected::after,
      .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected::before,
      .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected::after {
        background-color: var(--mdc-theme-primary, #6200ee); } }
  .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected:hover::before,
  .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected:hover::before {
    opacity: 0.08; }
  .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected:not(.mdc-ripple-upgraded):focus::before, .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected.mdc-ripple-upgraded--background-focused::before,
  .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected:not(.mdc-ripple-upgraded):focus::before,
  .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.16; }
  .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected:not(.mdc-ripple-upgraded)::after,
  .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected:not(.mdc-ripple-upgraded):active::after,
  .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.2; }
  .mdc-list--theme-dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected.mdc-ripple-upgraded,
  .mdc-theme--dark :not(.mdc-list--non-interactive) > .mdc-list-item--selected.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: $opacity; }

.mdc-list-divider {
  height: 0;
  margin: 0;
  border: none;
  border-bottom-width: 1px;
  border-bottom-style: solid; }

.mdc-list-divider {
  border-bottom-color: rgba(0, 0, 0, 0.12); }

.mdc-list-divider--padded {
  margin: 0 16px; }

.mdc-list-divider--inset {
  margin-left: 72px;
  margin-right: 0;
  width: calc(100% - 72px); }
  [dir="rtl"] .mdc-list-group .mdc-list-divider--inset,
  .mdc-list-group[dir="rtl"] .mdc-list-divider--inset {
    margin-left: 0;
    margin-right: 72px; }

.mdc-list-divider--inset.mdc-list-divider--padded {
  width: calc(100% - 72px - 16px); }

.mdc-list-group--theme-dark .mdc-list-group__subheader,
.mdc-theme--dark .mdc-list-group .mdc-list-group__subheader {
  /* @alternate */
  color: white;
  color: var(--mdc-theme-text-primary-on-dark, white); }

.mdc-list-group__subheader {
  font-family: "Open Sans", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.04em;
  text-decoration: inherit;
  text-transform: inherit;
  margin: 0.75rem 16px; }

.mdc-list-group .mdc-list {
  padding: 0; }

.mdc-list-group__subheader {
  /* @alternate */
  color: rgba(0, 0, 0, 0.87);
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87)); }

/**
 * The css property used for elevation. In most cases this should not be changed. It is exposed
 * as a variable for abstraction / easy use when needing to reference the property directly, for
 * example in a `will-change` rule.
 */
/**
 * The default duration value for elevation transitions.
 */
/**
 * The default easing value for elevation transitions.
 */
/**
 * Applies the correct CSS rules to an element to give it the elevation specified by $z-value.
 * The $z-value must be between 0 and 24.
 * If $color has an alpha channel, it will be ignored and overridden. To increase the opacity of the shadow, use
 * $opacity-boost.
 */
/**
 * Returns a string that can be used as the value for a `transition` property for elevation.
 * Calling this function directly is useful in situations where a component needs to transition
 * more than one property.
 *
 * ```scss
 * .foo {
 *   transition: mdc-elevation-transition-value(), opacity 100ms ease;
 *   will-change: $mdc-elevation-property, opacity;
 * }
 * ```
 */
/* TODO(sgomes): Figure out what to do about desktop font sizes. */
/* TODO(sgomes): Figure out what to do about i18n and i18n font sizes. */
.mdc-simple-menu {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  display: none;
  position: absolute;
  box-sizing: border-box;
  min-width: 170px;
  max-width: calc(100vw - 32px);
  max-height: calc(100vh - 32px);
  margin: 0;
  padding: 0;
  transform: scale(1);
  transform-origin: top left;
  border-radius: 2px;
  background-color: white;
  opacity: 0;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: auto;
  will-change: transform, opacity;
  z-index: 4; }
  .mdc-simple-menu--theme-dark,
  .mdc-theme--dark .mdc-simple-menu {
    background-color: #424242; }
  .mdc-simple-menu:focus {
    outline: none; }
  .mdc-simple-menu--animating-open {
    display: inline-block;
    overflow-y: hidden;
    opacity: 0;
    transform: scale(0.8);
    transition: opacity 0.03s linear, transform 0.12s cubic-bezier(0, 0, 0.2, 1); }
    .mdc-simple-menu--animating-open > .mdc-simple-menu__items {
      transform: scale(1.25); }
  .mdc-simple-menu--open {
    display: inline-block;
    transform: scale(1);
    opacity: 1; }
    .mdc-simple-menu--open > .mdc-simple-menu__items {
      transform: scale(1); }
  .mdc-simple-menu--animating-closed {
    display: inline-block;
    overflow-y: hidden;
    opacity: 0;
    transition: opacity 0.075s linear; }
  .mdc-simple-menu__items {
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    will-change: transform;
    transform: scale(1); }
    .mdc-simple-menu__items > .mdc-list-item {
      cursor: pointer; }
    .mdc-simple-menu--animating .mdc-simple-menu__items {
      overflow-y: hidden; }
  [dir="rtl"] .mdc-simple-menu {
    transform-origin: top right; }
  .mdc-simple-menu .mdc-list-group,
  .mdc-simple-menu .mdc-list {
    padding: 8px 0; }
  .mdc-simple-menu .mdc-list-item {
    font-family: "Open Sans", sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 1rem;
    line-height: 1.75rem;
    font-weight: 400;
    letter-spacing: 0.04em;
    text-decoration: inherit;
    text-transform: inherit;
    position: relative;
    outline: none;
    color: inherit;
    text-decoration: none;
    user-select: none; }
    .mdc-simple-menu--theme-dark.mdc-simple-menu .mdc-list-item,
    .mdc-theme--dark .mdc-simple-menu .mdc-list-item {
      color: white; }
  .mdc-simple-menu--theme-dark.mdc-simple-menu .mdc-list-divider,
  .mdc-theme--dark .mdc-simple-menu .mdc-list-divider {
    border-color: rgba(255, 255, 255, 0.12); }
  .mdc-simple-menu .mdc-list-item__graphic {
    color: rgba(0, 0, 0, 0.54); }
    .mdc-simple-menu--theme-dark.mdc-simple-menu .mdc-list-item__graphic,
    .mdc-theme--dark .mdc-simple-menu .mdc-list-item__graphic {
      color: rgba(255, 255, 255, 0.54); }
  .mdc-simple-menu .mdc-list-item[aria-disabled="true"] {
    /* @alternate */
    color: rgba(0, 0, 0, 0.38);
    color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38));
    cursor: default;
    pointer-events: none; }
    .mdc-simple-menu--theme-dark .mdc-simple-menu .mdc-list-item[aria-disabled="true"],
    .mdc-theme--dark .mdc-simple-menu .mdc-list-item[aria-disabled="true"] {
      /* @alternate */
      color: rgba(255, 255, 255, 0.5);
      color: var(--mdc-theme-text-disabled-on-dark, rgba(255, 255, 255, 0.5)); }
    .mdc-simple-menu .mdc-list-item[aria-disabled="true"]:focus::before {
      opacity: 0; }

.mdc-menu-anchor {
  position: relative;
  overflow: visible; }

/* TODO(sgomes): Figure out what to do about desktop font sizes. */
/* TODO(sgomes): Figure out what to do about i18n and i18n font sizes. */
/**
 * Creates a rule that will be applied when an MDC-Web component is within the context of an RTL layout.
 *
 * Usage Example:
 * ```scss
 * .mdc-foo {
 *   position: absolute;
 *   left: 0;
 *
 *   @include mdc-rtl {
 *     left: auto;
 *     right: 0;
 *   }
 *
 *   &__bar {
 *     margin-left: 4px;
 *     @include mdc-rtl(".mdc-foo") {
 *       margin-left: auto;
 *       margin-right: 4px;
 *     }
 *   }
 * }
 *
 * .mdc-foo--mod {
 *   padding-left: 4px;
 *
 *   @include mdc-rtl {
 *     padding-left: auto;
 *     padding-right: 4px;
 *   }
 * }
 * ```
 *
 * Note that this works by checking for [dir="rtl"] on an ancestor element. While this will work
 * in most cases, it will in some cases lead to false negatives, e.g.
 *
 * ```html
 * <html dir="rtl">
 *   <!-- ... -->
 *   <div dir="ltr">
 *     <div class="mdc-foo">Styled incorrectly as RTL!</div>
 *   </div>
 * </html>
 * ```
 *
 * In the future, selectors such as :dir (http://mdn.io/:dir) will help us mitigate this.
 */
/**
 * Takes a base box-model property - e.g. margin / border / padding - along with a default
 * direction and value, and emits rules which apply the value to the
 * "<base-property>-<default-direction>" property by default, but flips the direction
 * when within an RTL context.
 *
 * For example:
 *
 * ```scss
 * .mdc-foo {
 *   @include mdc-rtl-reflexive-box(margin, left, 8px);
 * }
 * ```
 * is equivalent to:
 *
 * ```scss
 * .mdc-foo {
 *   margin-left: 8px;
 *
 *   @include mdc-rtl {
 *     margin-right: 8px;
 *     margin-left: 0;
 *   }
 * }
 * ```
 * whereas:
 *
 * ```scss
 * .mdc-foo {
 *   @include mdc-rtl-reflexive-box(margin, right, 8px);
 * }
 * ```
 * is equivalent to:
 *
 * ```scss
 * .mdc-foo {
 *   margin-right: 8px;
 *
 *   @include mdc-rtl {
 *     margin-right: 0;
 *     margin-left: 8px;
 *   }
 * }
 * ```
 *
 * You can also pass a 4th optional $root-selector argument which will be forwarded to `mdc-rtl`,
 * e.g. `@include mdc-rtl-reflexive-box(margin, left, 8px, ".mdc-component")`.
 *
 * Note that this function will always zero out the original value in an RTL context. If you're
 * trying to flip the values, use mdc-rtl-reflexive-property().
 */
/**
 * Takes a base property and emits rules that assign <base-property>-left to <left-value> and
 * <base-property>-right to <right-value> in a LTR context, and vice versa in a RTL context.
 * For example:
 *
 * ```scss
 * .mdc-foo {
 *   @include mdc-rtl-reflexive-property(margin, auto, 12px);
 * }
 * ```
 * is equivalent to:
 *
 * ```scss
 * .mdc-foo {
 *   margin-left: auto;
 *   margin-right: 12px;
 *
 *   @include mdc-rtl {
 *     margin-left: 12px;
 *     margin-right: auto;
 *   }
 * }
 * ```
 *
 * A 4th optional $root-selector argument can be given, which will be passed to `mdc-rtl`.
 */
/**
 * Takes an argument specifying a horizontal position property (either "left" or "right") as well
 * as a value, and applies that value to the specified position in a LTR context, and flips it in a
 * RTL context. For example:
 *
 * ```scss
 * .mdc-foo {
 *   @include mdc-rtl-reflexive-position(left, 0);
 *   position: absolute;
 * }
 * ```
 * is equivalent to:
 *
 * ```scss
 *  .mdc-foo {
 *    position: absolute;
 *    left: 0;
 *    right: initial;
 *
 *    @include mdc-rtl {
 *      right: 0;
 *      left: initial;
 *    }
 *  }
 * ```
 * An optional third $root-selector argument may also be given, which is passed to `mdc-rtl`.
 */
.mdc-select {
  font-family: "Open Sans", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.04em;
  text-decoration: inherit;
  text-transform: inherit;
  /* @alternate */
  color: rgba(0, 0, 0, 0.87);
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
  background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%230%22%20fill-rule%3D%22evenodd%22%20opacity%3D%220.54%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E);
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  height: 56px;
  border: none;
  border-radius: 4px 4px 0 0;
  outline: none;
  background-repeat: no-repeat;
  background-position: right 10px center;
  cursor: pointer;
  overflow: visible; }
  [dir="rtl"] .mdc-select, .mdc-select[dir="rtl"] {
    background-position: left 10px center; }
  .mdc-select--theme-dark .mdc-select,
  .mdc-theme--dark .mdc-select {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%23fff%22%20fill-rule%3D%22evenodd%22%20opacity%3D%220.54%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E);
    background-color: rgba(255, 255, 255, 0.1); }
  .mdc-select__menu {
    position: fixed;
    top: 0;
    left: 0;
    max-height: 100%;
    transform-origin: center center;
    z-index: 4; }
  .mdc-select__surface {
    font-family: "Open Sans", sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 1rem;
    line-height: 1.75rem;
    font-weight: 400;
    letter-spacing: 0.04em;
    text-decoration: inherit;
    text-transform: inherit;
    /* @alternate */
    color: rgba(0, 0, 0, 0.87);
    color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
    padding-left: 16px;
    padding-right: 26px;
    --mdc-ripple-fg-size: 0;
    --mdc-ripple-left: 0;
    --mdc-ripple-top: 0;
    --mdc-ripple-fg-scale: 1;
    --mdc-ripple-fg-translate-end: 0;
    --mdc-ripple-fg-translate-start: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    will-change: transform, opacity;
    display: flex;
    position: relative;
    flex-grow: 1;
    width: 100%;
    height: 56px;
    border: none;
    border-radius: 4px 4px 0 0;
    outline: none;
    background-color: rgba(0, 0, 0, 0.04);
    appearance: none;
    overflow: hidden; }
    [dir="rtl"] .mdc-select .mdc-select__surface,
    .mdc-select[dir="rtl"] .mdc-select__surface {
      padding-left: 26px;
      padding-right: 16px; }
    .mdc-select__surface::before, .mdc-select__surface::after {
      position: absolute;
      border-radius: 50%;
      opacity: 0;
      pointer-events: none;
      content: ""; }
    .mdc-select__surface::before {
      transition: opacity 15ms linear; }
    .mdc-select__surface.mdc-ripple-upgraded::after {
      top: 0;
      left: 0;
      transform: scale(0);
      transform-origin: center center; }
    .mdc-select__surface.mdc-ripple-upgraded--unbounded::after {
      top: var(--mdc-ripple-top, 0);
      left: var(--mdc-ripple-left, 0); }
    .mdc-select__surface.mdc-ripple-upgraded--foreground-activation::after {
      animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
    .mdc-select__surface.mdc-ripple-upgraded--foreground-deactivation::after {
      animation: 150ms mdc-ripple-fg-opacity-out;
      transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
    .mdc-select__surface::before, .mdc-select__surface::after {
      top: calc(50% - 100%);
      left: calc(50% - 100%);
      width: 200%;
      height: 200%; }
    .mdc-select__surface.mdc-ripple-upgraded::before {
      top: calc(50% - 100%);
      left: calc(50% - 100%);
      width: 200%;
      height: 200%;
      transform: scale(var(--mdc-ripple-fg-scale, 0)); }
    .mdc-select__surface.mdc-ripple-upgraded--unbounded::before {
      top: var(--mdc-ripple-top, calc(50% - 50%));
      left: var(--mdc-ripple-left, calc(50% - 50%));
      width: var(--mdc-ripple-fg-size, 100%);
      height: var(--mdc-ripple-fg-size, 100%);
      transform: scale(var(--mdc-ripple-fg-scale, 0)); }
    .mdc-select__surface.mdc-ripple-upgraded::after {
      width: var(--mdc-ripple-fg-size, 100%);
      height: var(--mdc-ripple-fg-size, 100%); }
    .mdc-select__surface::before, .mdc-select__surface::after {
      background-color: black; }
    .mdc-select__surface:hover::before {
      opacity: 0.04; }
    .mdc-select__surface:not(.mdc-ripple-upgraded):focus::before, .mdc-select__surface.mdc-ripple-upgraded--background-focused::before {
      transition-duration: 75ms;
      opacity: 0.12; }
    .mdc-select__surface:not(.mdc-ripple-upgraded)::after {
      transition: opacity 150ms linear; }
    .mdc-select__surface:not(.mdc-ripple-upgraded):active::after {
      transition-duration: 75ms;
      opacity: 0.16; }
    .mdc-select__surface.mdc-ripple-upgraded {
      --mdc-ripple-fg-opacity: $opacity; }
    .mdc-select--theme-dark .mdc-select__surface::before, .mdc-select--theme-dark .mdc-select__surface::after,
    .mdc-theme--dark .mdc-select__surface::before,
    .mdc-theme--dark .mdc-select__surface::after {
      background-color: white; }
    .mdc-select--theme-dark .mdc-select__surface:hover::before,
    .mdc-theme--dark .mdc-select__surface:hover::before {
      opacity: 0.08; }
    .mdc-select--theme-dark .mdc-select__surface:not(.mdc-ripple-upgraded):focus::before, .mdc-select--theme-dark .mdc-select__surface.mdc-ripple-upgraded--background-focused::before,
    .mdc-theme--dark .mdc-select__surface:not(.mdc-ripple-upgraded):focus::before,
    .mdc-theme--dark .mdc-select__surface.mdc-ripple-upgraded--background-focused::before {
      transition-duration: 75ms;
      opacity: 0.24; }
    .mdc-select--theme-dark .mdc-select__surface:not(.mdc-ripple-upgraded)::after,
    .mdc-theme--dark .mdc-select__surface:not(.mdc-ripple-upgraded)::after {
      transition: opacity 150ms linear; }
    .mdc-select--theme-dark .mdc-select__surface:not(.mdc-ripple-upgraded):active::after,
    .mdc-theme--dark .mdc-select__surface:not(.mdc-ripple-upgraded):active::after {
      transition-duration: 75ms;
      opacity: 0.32; }
    .mdc-select--theme-dark .mdc-select__surface.mdc-ripple-upgraded,
    .mdc-theme--dark .mdc-select__surface.mdc-ripple-upgraded {
      --mdc-ripple-fg-opacity: $opacity; }
    .mdc-select__surface::-ms-expand {
      display: none; }
  .mdc-select__label {
    left: 16px;
    right: initial;
    position: absolute;
    bottom: 12px;
    transform-origin: left top;
    transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1);
    color: rgba(0, 0, 0, 0.6);
    pointer-events: none;
    will-change: transform; }
    [dir="rtl"] .mdc-select__label, .mdc-select__label[dir="rtl"] {
      left: initial;
      right: 16px; }
    .mdc-select--theme-dark .mdc-select__label,
    .mdc-theme--dark .mdc-select__label {
      /* @alternate */
      color: rgba(255, 255, 255, 0.7);
      color: var(--mdc-theme-text-secondary-on-dark, rgba(255, 255, 255, 0.7)); }
    [dir="rtl"] .mdc-select .mdc-select__label,
    .mdc-select[dir="rtl"] .mdc-select__label {
      transform-origin: right top; }
    .mdc-select__label--float-above {
      transform: translateY(-40%) scale(0.75, 0.75); }
  .mdc-select__selected-text {
    display: flex;
    align-items: flex-end;
    margin-bottom: 6px;
    transition: opacity 125ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 125ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
    white-space: nowrap;
    overflow: hidden; }
    .mdc-select--theme-dark .mdc-select__selected-text,
    .mdc-theme--dark .mdc-select__selected-text {
      /* @alternate */
      color: rgba(255, 255, 255, 0.7);
      color: var(--mdc-theme-text-secondary-on-dark, rgba(255, 255, 255, 0.7)); }
  .mdc-select__bottom-line {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    transform: scaleY(1);
    transform-origin: bottom;
    transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1);
    background-color: rgba(0, 0, 0, 0.5); }
    .mdc-select__bottom-line::after {
      /* @alternate */
      background-color: #6200ee;
      background-color: var(--mdc-theme-primary, #6200ee);
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 2px;
      transform: scaleX(0);
      transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1);
      opacity: 0;
      content: "";
      z-index: 2; }
  .mdc-select__bottom-line--active::after {
    transform: scaleX(1);
    opacity: 1; }
  .mdc-select__surface:focus .mdc-select__bottom-line,
  .mdc-select__surface:focus ~ .mdc-select__bottom-line {
    /* @alternate */
    background-color: #6200ee;
    background-color: var(--mdc-theme-primary, #6200ee);
    transform: scaleY(2); }
    .mdc-select__surface:focus .mdc-select__bottom-line::after,
    .mdc-select__surface:focus ~ .mdc-select__bottom-line::after {
      opacity: 1; }

.mdc-select--open .mdc-select__surface::before {
  opacity: 0.12; }
  .mdc-select--theme-dark .mdc-select--open .mdc-select__surface::before,
  .mdc-theme--dark .mdc-select--open .mdc-select__surface::before {
    opacity: 0.24; }

.mdc-select--open .mdc-select__selected-text {
  transform: translateY(8px);
  transition: opacity 125ms 125ms cubic-bezier(0, 0, 0.2, 1), transform 125ms 125ms cubic-bezier(0, 0, 0.2, 1);
  opacity: 0; }

.mdc-select--open .mdc-select__bottom-line {
  /* @alternate */
  background-color: #6200ee;
  background-color: var(--mdc-theme-primary, #6200ee);
  transform: scaleY(2); }
  .mdc-select--open .mdc-select__bottom-line::after {
    opacity: 1; }

.mdc-select--disabled,
.mdc-select[disabled] {
  /* @alternate */
  color: rgba(0, 0, 0, 0.38);
  color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38));
  background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%230%22%20fill-rule%3D%22evenodd%22%20opacity%3D%220.38%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E);
  border-bottom-width: 1px;
  border-bottom-style: dotted;
  opacity: .38;
  cursor: default;
  pointer-events: none;
  user-select: none; }
  .mdc-select--disabled .mdc-select__bottom-line,
  .mdc-select[disabled] .mdc-select__bottom-line {
    display: none; }

.mdc-select--theme-dark.mdc-select--disabled,
.mdc-theme--dark .mdc-select--disabled {
  /* @alternate */
  color: rgba(255, 255, 255, 0.5);
  color: var(--mdc-theme-text-disabled-on-dark, rgba(255, 255, 255, 0.5));
  background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%23ffffff%22%20fill-rule%3D%22evenodd%22%20opacity%3D%220.38%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E);
  border-bottom: 1px dotted rgba(255, 255, 255, 0.38); }

.mdc-select--theme-dark.mdc-select[disabled],
.mdc-theme--dark .mdc-select[disabled] {
  /* @alternate */
  color: rgba(255, 255, 255, 0.5);
  color: var(--mdc-theme-text-disabled-on-dark, rgba(255, 255, 255, 0.5));
  background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%23ffffff%22%20fill-rule%3D%22evenodd%22%20opacity%3D%220.38%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E);
  border-bottom: 1px dotted rgba(255, 255, 255, 0.38); }

.mdc-select__menu .mdc-list-item {
  font-family: "Open Sans", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.04em;
  text-decoration: inherit;
  text-transform: inherit;
  /* @alternate */
  color: rgba(0, 0, 0, 0.54);
  color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54)); }
  .mdc-select__menu .mdc-list-item[aria-selected="true"] {
    /* @alternate */
    color: rgba(0, 0, 0, 0.87);
    color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)); }
  .mdc-select--theme-dark .mdc-select__menu .mdc-list-item,
  .mdc-theme--dark .mdc-select__menu .mdc-list-item {
    /* @alternate */
    color: rgba(255, 255, 255, 0.7);
    color: var(--mdc-theme-text-secondary-on-dark, rgba(255, 255, 255, 0.7)); }
    .mdc-select--theme-dark .mdc-select__menu .mdc-list-item[aria-selected="true"],
    .mdc-theme--dark .mdc-select__menu .mdc-list-item[aria-selected="true"] {
      /* @alternate */
      color: white;
      color: var(--mdc-theme-text-primary-on-dark, white); }
  .mdc-select__menu .mdc-list-item::before, .mdc-select__menu .mdc-list-item::after {
    top: calc(50% - 50%);
    left: calc(50% - 50%);
    width: 100%;
    height: 100%; }
  .mdc-select__menu .mdc-list-item.mdc-ripple-upgraded::before {
    top: calc(50% - 50%);
    left: calc(50% - 50%);
    width: 100%;
    height: 100%;
    transform: scale(var(--mdc-ripple-fg-scale, 0)); }
  .mdc-select__menu .mdc-list-item.mdc-ripple-upgraded--unbounded::before {
    top: var(--mdc-ripple-top, calc(50% - 25%));
    left: var(--mdc-ripple-left, calc(50% - 25%));
    width: var(--mdc-ripple-fg-size, 50%);
    height: var(--mdc-ripple-fg-size, 50%);
    transform: scale(var(--mdc-ripple-fg-scale, 0)); }
  .mdc-select__menu .mdc-list-item.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 50%);
    height: var(--mdc-ripple-fg-size, 50%); }
  .mdc-select__menu .mdc-list-item::before, .mdc-select__menu .mdc-list-item::after {
    border-radius: 0; }

.mdc-select__menu .mdc-list-group,
.mdc-select__menu .mdc-list-group > .mdc-list-item:first-child {
  margin-top: 12px; }

.mdc-select__menu .mdc-list-group {
  /* @alternate */
  color: rgba(0, 0, 0, 0.38);
  color: var(--mdc-theme-text-hint-on-light, rgba(0, 0, 0, 0.38));
  font-weight: normal; }
  .mdc-select__menu .mdc-list-group .mdc-list-item {
    /* @alternate */
    color: rgba(0, 0, 0, 0.87);
    color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)); }

.mdc-select--theme-dark .mdc-select__menu .mdc-list-group,
.mdc-theme--dark .mdc-select__menu .mdc-list-group {
  /* @alternate */
  color: rgba(255, 255, 255, 0.5);
  color: var(--mdc-theme-text-hint-on-dark, rgba(255, 255, 255, 0.5)); }
  .mdc-select--theme-dark .mdc-select__menu .mdc-list-group .mdc-list-item,
  .mdc-theme--dark .mdc-select__menu .mdc-list-group .mdc-list-item {
    /* @alternate */
    color: white;
    color: var(--mdc-theme-text-primary-on-dark, white); }

.mdc-multi-select {
  /* @alternate */
  border-color: rgba(0, 0, 0, 0.38);
  border-color: var(--mdc-theme-text-hint-on-light, rgba(0, 0, 0, 0.38));
  width: 250px;
  padding: 0;
  border-width: 1px;
  border-style: solid;
  outline: none;
  appearance: none; }
  .mdc-multi-select--theme-dark,
  .mdc-theme--dark .mdc-multi-select {
    /* @alternate */
    border-color: rgba(255, 255, 255, 0.5);
    border-color: var(--mdc-theme-text-hint-on-dark, rgba(255, 255, 255, 0.5)); }
  .mdc-multi-select .mdc-list-group {
    /* @alternate */
    color: rgba(0, 0, 0, 0.38);
    color: var(--mdc-theme-text-hint-on-light, rgba(0, 0, 0, 0.38));
    margin: 16px 0 0;
    padding: 0 0 0 16px;
    font-weight: normal; }
    .mdc-multi-select .mdc-list-group--theme-dark,
    .mdc-theme--dark .mdc-multi-select .mdc-list-group {
      /* @alternate */
      color: rgba(255, 255, 255, 0.5);
      color: var(--mdc-theme-text-hint-on-dark, rgba(255, 255, 255, 0.5)); }
    .mdc-multi-select .mdc-list-group:last-child {
      margin-bottom: 16px; }
    .mdc-multi-select .mdc-list-group .mdc-list-divider {
      margin-left: -16px; }
  .mdc-multi-select .mdc-list-item {
    /* @alternate */
    color: rgba(0, 0, 0, 0.87);
    color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
    margin: 0 0 0 -16px;
    padding: 0 16px; }
    .mdc-multi-select .mdc-list-item--theme-dark,
    .mdc-theme--dark .mdc-multi-select .mdc-list-item {
      /* @alternate */
      color: white;
      color: var(--mdc-theme-text-primary-on-dark, white); }
    .mdc-multi-select .mdc-list-item:first-child {
      margin-top: 12px; }
    .mdc-multi-select .mdc-list-item:last-child {
      margin-bottom: 8px; }
    .mdc-multi-select .mdc-list-item::before, .mdc-multi-select .mdc-list-item::after {
      content: none; }
  .mdc-multi-select .mdc-list-item:checked {
    /* @alternate */
    background-color: #fff;
    background-color: var(--mdc-theme-background, #fff); }
    .mdc-multi-select .mdc-list-item:checked--theme-dark,
    .mdc-theme--dark .mdc-multi-select .mdc-list-item:checked {
      /* @alternate */
      background-color: white;
      background-color: var(--mdc-theme-text-primary-on-dark, white); }
  .mdc-multi-select .mdc-list-divider {
    margin-bottom: 8px;
    padding-top: 8px;
    font-size: 0; }

.mdc-multi-select:focus .mdc-list-item:checked {
  /* @alternate */
  background-color: #6200ee;
  background-color: var(--mdc-theme-primary, #6200ee); }
  .mdc-multi-select:focus .mdc-list-item:checked--theme-dark,
  .mdc-theme--dark .mdc-multi-select:focus .mdc-list-item:checked {
    /* @alternate */
    background-color: white;
    background-color: var(--mdc-theme-text-primary-on-dark, white); }

.mdc-select-scroll-lock {
  overflow: hidden; }

.vc-app__confirm-dialog .vc-app__confirm-message {
  z-index: 2;
  position: absolute;
  background-color: #FFF;
  padding: 30px;
  width: 300px;
  left: calc(50% - 150px);
  top: calc(50% - 90px);
  font-size: 24px; }
  .vc-app__confirm-dialog .vc-app__confirm-message .vc-app__confirm-actions {
    margin-top: 25px; }
    .vc-app__confirm-dialog .vc-app__confirm-message .vc-app__confirm-actions .btn.red {
      margin-left: 10px; }

.vc-app__confirm-dialog .vc-app__confirm-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.5;
  background-color: #444;
  z-index: 1; }

.mdc-button.mdc-button--raised.vc-button__close-confirm-dialog {
  background-color: #FF0000;
  margin-left: 20px; }

table {
  width: 100%; }
  table.vc-table__event-log {
    margin-top: 10px; }

.vc-theme__error-message-container {
  display: inline-block;
  padding: 5px 10px;
  background-color: #F12b24; }
  .vc-theme__error-message-container .vc-theme__error-message {
    color: white;
    display: inline-block;
    margin: 0 10px; }

.vc-theme__container {
  margin: 0 10%; }

.vc-app__tabs .vc-app__tab {
  display: inline-block;
  padding: 5px 10px;
  cursor: pointer;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .vc-app__tabs .vc-app__tab i {
    vertical-align: bottom;
    margin-left: 10px;
    -webkit-transition: transform .6s ease-in-out;
    transition: transform .6s ease-in-out; }
  .vc-app__tabs .vc-app__tab:hover i {
    -webkit-transform: rotate(720deg);
    transform: rotate(720deg); }
  .vc-app__tabs .vc-app__tab:not(.active):hover {
    background-color: #D1D1D1; }
  .vc-app__tabs .vc-app__tab.active {
    border: 1px solid #DEDEDE;
    border-bottom: 0;
    font-weight: 600;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px; }

.vc-theme__editing-mode-container {
  margin-top: 20px; }
  .vc-theme__editing-mode-container.vc-app__tabs .vc-app__tab:not(.active) {
    border-bottom: 1px solid #DEDEDE; }

.vc-theme__theme-name-container {
  margin: 10px 0 0;
  padding: 4px 24px; }
  .vc-theme__theme-name-container .vc-theme__theme-name {
    font-size: 16px; }
    .vc-theme__theme-name-container .vc-theme__theme-name .vc-theme__theme-heading {
      font-weight: 600; }
    .vc-theme__theme-name-container .vc-theme__theme-name .name {
      margin-left: 10px; }
      .vc-theme__theme-name-container .vc-theme__theme-name .name span {
        margin-right: 5px;
        padding: 5px; }
        .vc-theme__theme-name-container .vc-theme__theme-name .name span:hover {
          background-color: #BCBCBC;
          cursor: pointer; }
    .vc-theme__theme-name-container .vc-theme__theme-name .vc-theme__edit-theme-name {
      display: inline-block;
      width: auto;
      height: 16px;
      font-size: 16px;
      margin-left: 10px;
      margin-bottom: 0;
      border: none;
      box-shadow: none;
      padding: 4px 5px; }
      .vc-theme__theme-name-container .vc-theme__theme-name .vc-theme__edit-theme-name:focus {
        box-shadow: none;
        border: none; }

.vc-theme__preview {
  padding: 5px;
  height: 360px;
  background-color: #BCBCBC; }
  .vc-theme__preview #vc-theme__preview-container {
    height: 100%; }
    .vc-theme__preview #vc-theme__preview-container .vc-container {
      background: center url("https://i.imgur.com/IQjJq4W.jpg");
      background-size: cover; }

.error-marker {
  position: absolute;
  background-color: rgba(39, 208, 37, 0.6); }

.vc-button__show-gui-controls {
  margin-top: 20px; }

.vc-theme__controller {
  margin-top: 20px; }
  .vc-theme__controller .tab:not(.active) {
    border-bottom: 1px solid #DEDEDE; }

.vc-theme__control-panel {
  position: relative;
  margin-top: 20px;
  border: 1px solid #999; }
  .vc-theme__control-panel .mdc-layout-grid {
    padding: 0 24px; }
  .vc-theme__control-panel .vc-theme__panel-header {
    display: inline;
    padding: 0 4px;
    background-color: white;
    position: absolute;
    top: -13px;
    left: 20px; }
  .vc-theme__control-panel .mdc-slider {
    margin-top: 7px; }
  .vc-theme__control-panel .mdc-select {
    width: 100%;
    margin-top: 10px; }
  .vc-theme__control-panel .mdc-checkbox {
    margin-top: 10px; }
  .vc-theme__control-panel .field-label {
    padding: 20px 0; }
  .vc-theme__control-panel .input-field {
    margin-top: 0; }
    .vc-theme__control-panel .input-field input {
      margin-bottom: 0; }

.sass-params-form .param {
  padding: 10px 0; }

.sass-params-form .color-picker-container .selected-color-container .selected-color {
  border: 1px solid #BCBCBC;
  display: inline-block;
  width: 72px;
  height: 36px;
  cursor: pointer; }

.sass-params-form .color-picker-container .picker-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.sass-params-form .color-picker-container .sketch-picker {
  position: absolute;
  z-index: 1; }
  .sass-params-form .color-picker-container .sketch-picker input {
    height: auto;
    margin: 0; }

.toolbar {
  float: right;
  margin-top: -20px; }

.toolbar > div {
  padding-left: 5px;
  display: inline-block; }

.toolbar .btn {
  padding-left: 1rem;
  padding-right: 1rem; }

.sass-error {
  margin-left: 10px;
  color: #FF0000; }

.switch {
  position: relative; }

.left-docs-nav {
  padding-left: 0; }
  .left-docs-nav.fixed {
    position: fixed;
    width: 12%;
    top: 0; }
  .left-docs-nav li {
    height: 40px; }
    .left-docs-nav li a {
      display: inline-block;
      width: 100%;
      color: #BCBCBC;
      text-decoration: none; }
      .left-docs-nav li a:hover {
        color: #999;
        border-right: 1px solid #999; }
      .left-docs-nav li a.active {
        color: #4A4A4A;
        border-right: 2px solid #4A4A4A; }

.docs-container {
  padding: 10px;
  padding-top: 0; }

.reference table tr.table-heading {
  background-color: #DEDEDE; }

.reference table tr th:first-child, .reference table tr td:first-child {
  width: 250px; }

.reference table tr th:nth-child(2) {
  width: 200px; }

code.examples-section {
  font-size: 20px;
  line-height: 0.5; }
  code.examples-section .mar-l40 {
    margin-left: 40px; }
