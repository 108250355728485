.vc-container * {
	color: unset;
	opacity: unset;
	
	background: unset;
	border: unset;
	box-shadow: unset;
	
	bottom: unset;
	clear: unset;
	clip: unset;
	display: unset;
	float: unset;
	height: unset;
	left: unset;
	margin: unset;
	max-height: unset;
	max-width: unset;
	min-height: unset;
	min-width: unset;
	overflow: unset;
	padding: unset;
	position: unset;
	right: unset;
	top: unset;
	visibility: unset;
	width: unset;
	vertical-align: unset;
	z-index: unset;
	
	align-content: unset;
	align-items: unset;
	align-self: unset;
	flex: unset;
	justify-content: unset;
	order: unset;
	
	hyphens: unset;
	letter-spacing: unset;
	line-break: unset;
	line-height: unset;
	overflow-wrap: unset;
	tab-size: unset;
	text-align: unset;
	text-align-last: unset;
	text-combine-upright: unset;
	text-indent: unset;
	text-transform: unset;
	white-space: unset;
	word-break: unset;
	word-spacing: unset;
	word-wrap: unset;
	
	text-decoration: unset;
	text-shadow: unset;
	text-underline-position: unset;
	
	font: unset;
	font-weight: unset;
	
	direction: unset;
	text-orientation: unset;
	unicode-bidi: unset;
	user-select: unset;
	writing-mode: unset;
	
	border-collapse: unset;
	border-spacing: unset;
	caption-side: unset;
	empty-cells: unset;
	table-layout: unset;
	
	counter-increment: unset;
	counter-reset: unset;
	list-style: unset;
	
	animation: unset;
	
	backface-visibility: unset;
	perspective: unset;
	transform: unset;
	
	transition: unset;
	
	box-sizing: unset;
	content: unset;
	cursor: unset;
	outline: unset;
	resize: unset;
	text-overflow: unset;
	
	column-rule: unset;
	column-count: unset;
	column-fill: unset;
	column-gap: unset;
	column-span: unset;
	column-width: unset;
	columns: unset;
	widows: unset;
	
	orphans: unset;
	
	quotes: unset;
	
	filter: unset;
	
	image-rendering: unset;
	object-fit: unset;
	object-position: unset;
}

.vc-container address, .vc-container article, .vc-container aside,
.vc-container blockquote, .vc-container canvas, .vc-container dd,
.vc-container div, .vc-container dl, .vc-container dt,
.vc-container fieldset, .vc-container figcaption, .vc-container figure,
.vc-container figcaption, .vc-container footer, .vc-container form,
.vc-container h1, .vc-container h2, .vc-container h3,
.vc-container h4, .vc-container h5, .vc-container h6,
.vc-container header, .vc-container hgroup, .vc-container hr,
.vc-container li, .vc-container main, .vc-container nav,
.vc-container noscript, .vc-container ol, .vc-container output,
.vc-container p, .vc-container pre, .vc-container section,
.vc-container table, .vc-container tfoot, .vc-container ul,
.vc-container video {
	display: block;
}