:root {
  --mdc-theme-primary: #6200ee;
  --mdc-theme-primary-light: #b37eff;
  --mdc-theme-primary-dark: #8936ff;
  --mdc-theme-secondary: #018786;
  --mdc-theme-secondary-light: #02cecc;
  --mdc-theme-secondary-dark: #004040;
  --mdc-theme-background: #fff;
  --mdc-theme-text-primary-on-primary: white;
  --mdc-theme-text-secondary-on-primary: rgba(255, 255, 255, 0.7);
  --mdc-theme-text-hint-on-primary: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-disabled-on-primary: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-icon-on-primary: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-primary-on-primary-light: rgba(0, 0, 0, 0.87);
  --mdc-theme-text-secondary-on-primary-light: rgba(0, 0, 0, 0.54);
  --mdc-theme-text-hint-on-primary-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-disabled-on-primary-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-icon-on-primary-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-primary-on-primary-dark: white;
  --mdc-theme-text-secondary-on-primary-dark: rgba(255, 255, 255, 0.7);
  --mdc-theme-text-hint-on-primary-dark: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-disabled-on-primary-dark: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-icon-on-primary-dark: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-primary-on-secondary: white;
  --mdc-theme-text-secondary-on-secondary: rgba(255, 255, 255, 0.7);
  --mdc-theme-text-hint-on-secondary: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-disabled-on-secondary: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-icon-on-secondary: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-primary-on-secondary-light: rgba(0, 0, 0, 0.87);
  --mdc-theme-text-secondary-on-secondary-light: rgba(0, 0, 0, 0.54);
  --mdc-theme-text-hint-on-secondary-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-disabled-on-secondary-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-icon-on-secondary-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-primary-on-secondary-dark: white;
  --mdc-theme-text-secondary-on-secondary-dark: rgba(255, 255, 255, 0.7);
  --mdc-theme-text-hint-on-secondary-dark: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-disabled-on-secondary-dark: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-icon-on-secondary-dark: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-primary-on-background: rgba(0, 0, 0, 0.87);
  --mdc-theme-text-secondary-on-background: rgba(0, 0, 0, 0.54);
  --mdc-theme-text-hint-on-background: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-disabled-on-background: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-icon-on-background: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-primary-on-light: rgba(0, 0, 0, 0.87);
  --mdc-theme-text-secondary-on-light: rgba(0, 0, 0, 0.54);
  --mdc-theme-text-hint-on-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-disabled-on-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-icon-on-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-primary-on-dark: white;
  --mdc-theme-text-secondary-on-dark: rgba(255, 255, 255, 0.7);
  --mdc-theme-text-hint-on-dark: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-disabled-on-dark: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-icon-on-dark: rgba(255, 255, 255, 0.5); }

.mdc-theme--background {
  /* @alternate */
  background-color: #fff;
  background-color: var(--mdc-theme-background, #fff); }

.mdc-theme--primary {
  /* @alternate */
  color: #6200ee !important;
  color: var(--mdc-theme-primary, #6200ee) !important; }

.mdc-theme--primary-light {
  /* @alternate */
  color: #b37eff !important;
  color: var(--mdc-theme-primary-light, #b37eff) !important; }

.mdc-theme--primary-dark {
  /* @alternate */
  color: #8936ff !important;
  color: var(--mdc-theme-primary-dark, #8936ff) !important; }

.mdc-theme--secondary {
  /* @alternate */
  color: #018786 !important;
  color: var(--mdc-theme-secondary, #018786) !important; }

.mdc-theme--secondary-light {
  /* @alternate */
  color: #02cecc !important;
  color: var(--mdc-theme-secondary-light, #02cecc) !important; }

.mdc-theme--secondary-dark {
  /* @alternate */
  color: #004040 !important;
  color: var(--mdc-theme-secondary-dark, #004040) !important; }

.mdc-theme--text-primary-on-primary {
  /* @alternate */
  color: white !important;
  color: var(--mdc-theme-text-primary-on-primary, white) !important; }

.mdc-theme--text-secondary-on-primary {
  /* @alternate */
  color: rgba(255, 255, 255, 0.7) !important;
  color: var(--mdc-theme-text-secondary-on-primary, rgba(255, 255, 255, 0.7)) !important; }

.mdc-theme--text-hint-on-primary {
  /* @alternate */
  color: rgba(255, 255, 255, 0.5) !important;
  color: var(--mdc-theme-text-hint-on-primary, rgba(255, 255, 255, 0.5)) !important; }

.mdc-theme--text-disabled-on-primary {
  /* @alternate */
  color: rgba(255, 255, 255, 0.5) !important;
  color: var(--mdc-theme-text-disabled-on-primary, rgba(255, 255, 255, 0.5)) !important; }

.mdc-theme--text-icon-on-primary {
  /* @alternate */
  color: rgba(255, 255, 255, 0.5) !important;
  color: var(--mdc-theme-text-icon-on-primary, rgba(255, 255, 255, 0.5)) !important; }

.mdc-theme--text-primary-on-primary-light {
  /* @alternate */
  color: rgba(0, 0, 0, 0.87) !important;
  color: var(--mdc-theme-text-primary-on-primary-light, rgba(0, 0, 0, 0.87)) !important; }

.mdc-theme--text-secondary-on-primary-light {
  /* @alternate */
  color: rgba(0, 0, 0, 0.54) !important;
  color: var(--mdc-theme-text-secondary-on-primary-light, rgba(0, 0, 0, 0.54)) !important; }

.mdc-theme--text-hint-on-primary-light {
  /* @alternate */
  color: rgba(0, 0, 0, 0.38) !important;
  color: var(--mdc-theme-text-hint-on-primary-light, rgba(0, 0, 0, 0.38)) !important; }

.mdc-theme--text-disabled-on-primary-light {
  /* @alternate */
  color: rgba(0, 0, 0, 0.38) !important;
  color: var(--mdc-theme-text-disabled-on-primary-light, rgba(0, 0, 0, 0.38)) !important; }

.mdc-theme--text-icon-on-primary-light {
  /* @alternate */
  color: rgba(0, 0, 0, 0.38) !important;
  color: var(--mdc-theme-text-icon-on-primary-light, rgba(0, 0, 0, 0.38)) !important; }

.mdc-theme--text-primary-on-primary-dark {
  /* @alternate */
  color: white !important;
  color: var(--mdc-theme-text-primary-on-primary-dark, white) !important; }

.mdc-theme--text-secondary-on-primary-dark {
  /* @alternate */
  color: rgba(255, 255, 255, 0.7) !important;
  color: var(--mdc-theme-text-secondary-on-primary-dark, rgba(255, 255, 255, 0.7)) !important; }

.mdc-theme--text-hint-on-primary-dark {
  /* @alternate */
  color: rgba(255, 255, 255, 0.5) !important;
  color: var(--mdc-theme-text-hint-on-primary-dark, rgba(255, 255, 255, 0.5)) !important; }

.mdc-theme--text-disabled-on-primary-dark {
  /* @alternate */
  color: rgba(255, 255, 255, 0.5) !important;
  color: var(--mdc-theme-text-disabled-on-primary-dark, rgba(255, 255, 255, 0.5)) !important; }

.mdc-theme--text-icon-on-primary-dark {
  /* @alternate */
  color: rgba(255, 255, 255, 0.5) !important;
  color: var(--mdc-theme-text-icon-on-primary-dark, rgba(255, 255, 255, 0.5)) !important; }

.mdc-theme--text-primary-on-secondary {
  /* @alternate */
  color: white !important;
  color: var(--mdc-theme-text-primary-on-secondary, white) !important; }

.mdc-theme--text-secondary-on-secondary {
  /* @alternate */
  color: rgba(255, 255, 255, 0.7) !important;
  color: var(--mdc-theme-text-secondary-on-secondary, rgba(255, 255, 255, 0.7)) !important; }

.mdc-theme--text-hint-on-secondary {
  /* @alternate */
  color: rgba(255, 255, 255, 0.5) !important;
  color: var(--mdc-theme-text-hint-on-secondary, rgba(255, 255, 255, 0.5)) !important; }

.mdc-theme--text-disabled-on-secondary {
  /* @alternate */
  color: rgba(255, 255, 255, 0.5) !important;
  color: var(--mdc-theme-text-disabled-on-secondary, rgba(255, 255, 255, 0.5)) !important; }

.mdc-theme--text-icon-on-secondary {
  /* @alternate */
  color: rgba(255, 255, 255, 0.5) !important;
  color: var(--mdc-theme-text-icon-on-secondary, rgba(255, 255, 255, 0.5)) !important; }

.mdc-theme--text-primary-on-secondary-light {
  /* @alternate */
  color: rgba(0, 0, 0, 0.87) !important;
  color: var(--mdc-theme-text-primary-on-secondary-light, rgba(0, 0, 0, 0.87)) !important; }

.mdc-theme--text-secondary-on-secondary-light {
  /* @alternate */
  color: rgba(0, 0, 0, 0.54) !important;
  color: var(--mdc-theme-text-secondary-on-secondary-light, rgba(0, 0, 0, 0.54)) !important; }

.mdc-theme--text-hint-on-secondary-light {
  /* @alternate */
  color: rgba(0, 0, 0, 0.38) !important;
  color: var(--mdc-theme-text-hint-on-secondary-light, rgba(0, 0, 0, 0.38)) !important; }

.mdc-theme--text-disabled-on-secondary-light {
  /* @alternate */
  color: rgba(0, 0, 0, 0.38) !important;
  color: var(--mdc-theme-text-disabled-on-secondary-light, rgba(0, 0, 0, 0.38)) !important; }

.mdc-theme--text-icon-on-secondary-light {
  /* @alternate */
  color: rgba(0, 0, 0, 0.38) !important;
  color: var(--mdc-theme-text-icon-on-secondary-light, rgba(0, 0, 0, 0.38)) !important; }

.mdc-theme--text-primary-on-secondary-dark {
  /* @alternate */
  color: white !important;
  color: var(--mdc-theme-text-primary-on-secondary-dark, white) !important; }

.mdc-theme--text-secondary-on-secondary-dark {
  /* @alternate */
  color: rgba(255, 255, 255, 0.7) !important;
  color: var(--mdc-theme-text-secondary-on-secondary-dark, rgba(255, 255, 255, 0.7)) !important; }

.mdc-theme--text-hint-on-secondary-dark {
  /* @alternate */
  color: rgba(255, 255, 255, 0.5) !important;
  color: var(--mdc-theme-text-hint-on-secondary-dark, rgba(255, 255, 255, 0.5)) !important; }

.mdc-theme--text-disabled-on-secondary-dark {
  /* @alternate */
  color: rgba(255, 255, 255, 0.5) !important;
  color: var(--mdc-theme-text-disabled-on-secondary-dark, rgba(255, 255, 255, 0.5)) !important; }

.mdc-theme--text-icon-on-secondary-dark {
  /* @alternate */
  color: rgba(255, 255, 255, 0.5) !important;
  color: var(--mdc-theme-text-icon-on-secondary-dark, rgba(255, 255, 255, 0.5)) !important; }

.mdc-theme--text-primary-on-background {
  /* @alternate */
  color: rgba(0, 0, 0, 0.87) !important;
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87)) !important; }

.mdc-theme--text-secondary-on-background {
  /* @alternate */
  color: rgba(0, 0, 0, 0.54) !important;
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54)) !important; }

.mdc-theme--text-hint-on-background {
  /* @alternate */
  color: rgba(0, 0, 0, 0.38) !important;
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38)) !important; }

.mdc-theme--text-disabled-on-background {
  /* @alternate */
  color: rgba(0, 0, 0, 0.38) !important;
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38)) !important; }

.mdc-theme--text-icon-on-background {
  /* @alternate */
  color: rgba(0, 0, 0, 0.38) !important;
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38)) !important; }

.mdc-theme--text-primary-on-light {
  /* @alternate */
  color: rgba(0, 0, 0, 0.87) !important;
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)) !important; }

.mdc-theme--text-secondary-on-light {
  /* @alternate */
  color: rgba(0, 0, 0, 0.54) !important;
  color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54)) !important; }

.mdc-theme--text-hint-on-light {
  /* @alternate */
  color: rgba(0, 0, 0, 0.38) !important;
  color: var(--mdc-theme-text-hint-on-light, rgba(0, 0, 0, 0.38)) !important; }

.mdc-theme--text-disabled-on-light {
  /* @alternate */
  color: rgba(0, 0, 0, 0.38) !important;
  color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38)) !important; }

.mdc-theme--text-icon-on-light {
  /* @alternate */
  color: rgba(0, 0, 0, 0.38) !important;
  color: var(--mdc-theme-text-icon-on-light, rgba(0, 0, 0, 0.38)) !important; }

.mdc-theme--text-primary-on-dark {
  /* @alternate */
  color: white !important;
  color: var(--mdc-theme-text-primary-on-dark, white) !important; }

.mdc-theme--text-secondary-on-dark {
  /* @alternate */
  color: rgba(255, 255, 255, 0.7) !important;
  color: var(--mdc-theme-text-secondary-on-dark, rgba(255, 255, 255, 0.7)) !important; }

.mdc-theme--text-hint-on-dark {
  /* @alternate */
  color: rgba(255, 255, 255, 0.5) !important;
  color: var(--mdc-theme-text-hint-on-dark, rgba(255, 255, 255, 0.5)) !important; }

.mdc-theme--text-disabled-on-dark {
  /* @alternate */
  color: rgba(255, 255, 255, 0.5) !important;
  color: var(--mdc-theme-text-disabled-on-dark, rgba(255, 255, 255, 0.5)) !important; }

.mdc-theme--text-icon-on-dark {
  /* @alternate */
  color: rgba(255, 255, 255, 0.5) !important;
  color: var(--mdc-theme-text-icon-on-dark, rgba(255, 255, 255, 0.5)) !important; }

.mdc-theme--primary-bg {
  /* @alternate */
  background-color: #6200ee !important;
  background-color: var(--mdc-theme-primary, #6200ee) !important; }

.mdc-theme--primary-light-bg {
  /* @alternate */
  background-color: #b37eff !important;
  background-color: var(--mdc-theme-primary-light, #b37eff) !important; }

.mdc-theme--primary-dark-bg {
  /* @alternate */
  background-color: #8936ff !important;
  background-color: var(--mdc-theme-primary-dark, #8936ff) !important; }

.mdc-theme--secondary-bg {
  /* @alternate */
  background-color: #018786 !important;
  background-color: var(--mdc-theme-secondary, #018786) !important; }

.mdc-theme--secondary-light-bg {
  /* @alternate */
  background-color: #02cecc !important;
  background-color: var(--mdc-theme-secondary-light, #02cecc) !important; }

.mdc-theme--secondary-dark-bg {
  /* @alternate */
  background-color: #004040 !important;
  background-color: var(--mdc-theme-secondary-dark, #004040) !important; }

/**
 * The css property used for elevation. In most cases this should not be changed. It is exposed
 * as a variable for abstraction / easy use when needing to reference the property directly, for
 * example in a `will-change` rule.
 */
/**
 * The default duration value for elevation transitions.
 */
/**
 * The default easing value for elevation transitions.
 */
/**
 * Applies the correct CSS rules to an element to give it the elevation specified by $z-value.
 * The $z-value must be between 0 and 24.
 * If $color has an alpha channel, it will be ignored and overridden. To increase the opacity of the shadow, use
 * $opacity-boost.
 */
/**
 * Returns a string that can be used as the value for a `transition` property for elevation.
 * Calling this function directly is useful in situations where a component needs to transition
 * more than one property.
 *
 * ```scss
 * .foo {
 *   transition: mdc-elevation-transition-value(), opacity 100ms ease;
 *   will-change: $mdc-elevation-property, opacity;
 * }
 * ```
 */
/**
 * Creates a rule that will be applied when an MDC-Web component is within the context of an RTL layout.
 *
 * Usage Example:
 * ```scss
 * .mdc-foo {
 *   position: absolute;
 *   left: 0;
 *
 *   @include mdc-rtl {
 *     left: auto;
 *     right: 0;
 *   }
 *
 *   &__bar {
 *     margin-left: 4px;
 *     @include mdc-rtl(".mdc-foo") {
 *       margin-left: auto;
 *       margin-right: 4px;
 *     }
 *   }
 * }
 *
 * .mdc-foo--mod {
 *   padding-left: 4px;
 *
 *   @include mdc-rtl {
 *     padding-left: auto;
 *     padding-right: 4px;
 *   }
 * }
 * ```
 *
 * Note that this works by checking for [dir="rtl"] on an ancestor element. While this will work
 * in most cases, it will in some cases lead to false negatives, e.g.
 *
 * ```html
 * <html dir="rtl">
 *   <!-- ... -->
 *   <div dir="ltr">
 *     <div class="mdc-foo">Styled incorrectly as RTL!</div>
 *   </div>
 * </html>
 * ```
 *
 * In the future, selectors such as :dir (http://mdn.io/:dir) will help us mitigate this.
 */
/**
 * Takes a base box-model property - e.g. margin / border / padding - along with a default
 * direction and value, and emits rules which apply the value to the
 * "<base-property>-<default-direction>" property by default, but flips the direction
 * when within an RTL context.
 *
 * For example:
 *
 * ```scss
 * .mdc-foo {
 *   @include mdc-rtl-reflexive-box(margin, left, 8px);
 * }
 * ```
 * is equivalent to:
 *
 * ```scss
 * .mdc-foo {
 *   margin-left: 8px;
 *
 *   @include mdc-rtl {
 *     margin-right: 8px;
 *     margin-left: 0;
 *   }
 * }
 * ```
 * whereas:
 *
 * ```scss
 * .mdc-foo {
 *   @include mdc-rtl-reflexive-box(margin, right, 8px);
 * }
 * ```
 * is equivalent to:
 *
 * ```scss
 * .mdc-foo {
 *   margin-right: 8px;
 *
 *   @include mdc-rtl {
 *     margin-right: 0;
 *     margin-left: 8px;
 *   }
 * }
 * ```
 *
 * You can also pass a 4th optional $root-selector argument which will be forwarded to `mdc-rtl`,
 * e.g. `@include mdc-rtl-reflexive-box(margin, left, 8px, ".mdc-component")`.
 *
 * Note that this function will always zero out the original value in an RTL context. If you're
 * trying to flip the values, use mdc-rtl-reflexive-property().
 */
/**
 * Takes a base property and emits rules that assign <base-property>-left to <left-value> and
 * <base-property>-right to <right-value> in a LTR context, and vice versa in a RTL context.
 * For example:
 *
 * ```scss
 * .mdc-foo {
 *   @include mdc-rtl-reflexive-property(margin, auto, 12px);
 * }
 * ```
 * is equivalent to:
 *
 * ```scss
 * .mdc-foo {
 *   margin-left: auto;
 *   margin-right: 12px;
 *
 *   @include mdc-rtl {
 *     margin-left: 12px;
 *     margin-right: auto;
 *   }
 * }
 * ```
 *
 * A 4th optional $root-selector argument can be given, which will be passed to `mdc-rtl`.
 */
/**
 * Takes an argument specifying a horizontal position property (either "left" or "right") as well
 * as a value, and applies that value to the specified position in a LTR context, and flips it in a
 * RTL context. For example:
 *
 * ```scss
 * .mdc-foo {
 *   @include mdc-rtl-reflexive-position(left, 0);
 *   position: absolute;
 * }
 * ```
 * is equivalent to:
 *
 * ```scss
 *  .mdc-foo {
 *    position: absolute;
 *    left: 0;
 *    right: initial;
 *
 *    @include mdc-rtl {
 *      right: 0;
 *      left: initial;
 *    }
 *  }
 * ```
 * An optional third $root-selector argument may also be given, which is passed to `mdc-rtl`.
 */
/* TODO(sgomes): Figure out what to do about desktop font sizes. */
/* TODO(sgomes): Figure out what to do about i18n and i18n font sizes. */
.mdc-toolbar {
  /* @alternate */
  background-color: #6200ee;
  background-color: var(--mdc-theme-primary, #6200ee);
  color: white;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%; }
  .mdc-toolbar__row {
    display: flex;
    position: relative;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    min-height: 64px; }
    @media (max-width: 959px) and (orientation: landscape) {
      .mdc-toolbar__row {
        min-height: 48px; } }
    @media (max-width: 599px) {
      .mdc-toolbar__row {
        min-height: 56px; } }
  .mdc-toolbar__section {
    display: inline-flex;
    flex: 1;
    align-items: flex-start;
    justify-content: center;
    min-width: 0;
    height: 100%;
    z-index: 1; }
    .mdc-toolbar__section--align-start {
      justify-content: flex-start;
      order: -1; }
    .mdc-toolbar__section--align-end {
      justify-content: flex-end;
      order: 1; }
  .mdc-toolbar__title {
    font-family: "Open Sans", sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-decoration: inherit;
    text-transform: inherit;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 24px;
    margin-right: 0;
    align-self: center;
    padding: 16px 0;
    line-height: 1.5rem;
    z-index: 1; }
    [dir="rtl"] .mdc-toolbar__title, .mdc-toolbar__title[dir="rtl"] {
      margin-left: 0;
      margin-right: 24px; }
  .mdc-toolbar__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    border: none;
    background-color: inherit;
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    padding-right: 12px;
    padding-left: 12px; }
  .mdc-toolbar__icon:last-of-type {
    padding-left: 12px;
    padding-right: 24px; }
    [dir="rtl"] .mdc-toolbar__icon:last-of-type, .mdc-toolbar__icon:last-of-type[dir="rtl"] {
      padding-left: 24px;
      padding-right: 12px; }
  .mdc-toolbar__menu-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    border: none;
    background-color: inherit;
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    padding-right: 24px;
    padding-left: 24px; }

.mdc-toolbar__menu-icon + .mdc-toolbar__title {
  margin-left: 8px;
  margin-right: 0; }
  [dir="rtl"] .mdc-toolbar__menu-icon + .mdc-toolbar__title, .mdc-toolbar__menu-icon + .mdc-toolbar__title[dir="rtl"] {
    margin-left: 0;
    margin-right: 8px; }

@media (max-width: 599px) {
  .mdc-toolbar__title {
    margin-left: 16px;
    margin-right: 0; }
    [dir="rtl"] .mdc-toolbar__title, .mdc-toolbar__title[dir="rtl"] {
      margin-left: 0;
      margin-right: 16px; }
  .mdc-toolbar__icon {
    padding-right: 8px;
    padding-left: 8px; }
  .mdc-toolbar__icon:last-of-type {
    padding-left: 8px;
    padding-right: 16px; }
    [dir="rtl"] .mdc-toolbar__icon:last-of-type, .mdc-toolbar__icon:last-of-type[dir="rtl"] {
      padding-left: 16px;
      padding-right: 8px; }
  .mdc-toolbar__menu-icon {
    padding-right: 16px;
    padding-left: 16px; }
  .mdc-toolbar__menu-icon + .mdc-toolbar__title {
    margin-left: 16px;
    margin-right: 0; }
    [dir="rtl"] .mdc-toolbar__menu-icon + .mdc-toolbar__title, .mdc-toolbar__menu-icon + .mdc-toolbar__title[dir="rtl"] {
      margin-left: 0;
      margin-right: 16px; } }

.mdc-toolbar--fixed {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4; }

.mdc-toolbar--flexible {
  --mdc-toolbar-ratio-to-extend-flexible: 4; }
  .mdc-toolbar--flexible .mdc-toolbar__row:first-child {
    height: 256px;
    height: calc(64px * var(--mdc-toolbar-ratio-to-extend-flexible, 4)); }
    @media (max-width: 599px) {
      .mdc-toolbar--flexible .mdc-toolbar__row:first-child {
        height: 224px;
        height: calc(56px * var(--mdc-toolbar-ratio-to-extend-flexible, 4)); } }
    .mdc-toolbar--flexible .mdc-toolbar__row:first-child::after {
      position: absolute;
      content: ""; }
  .mdc-toolbar--flexible-default-behavior .mdc-toolbar__title {
    font-family: "Open Sans", sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 2.125rem;
    line-height: 2.5rem;
    font-weight: 400;
    letter-spacing: normal;
    text-decoration: inherit;
    text-transform: inherit;
    align-self: flex-end;
    line-height: 1.5rem; }
  .mdc-toolbar--flexible-default-behavior .mdc-toolbar__row:first-child::after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity .2s ease;
    opacity: 1; }
  .mdc-toolbar--flexible-default-behavior.mdc-toolbar--flexible-space-minimized .mdc-toolbar__row:first-child::after {
    opacity: 0; }
  .mdc-toolbar--flexible-default-behavior.mdc-toolbar--flexible-space-minimized .mdc-toolbar__title {
    font-weight: 500; }

.mdc-toolbar--waterfall.mdc-toolbar--fixed {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  will-change: box-shadow; }
  .mdc-toolbar--waterfall.mdc-toolbar--fixed.mdc-toolbar--flexible-space-minimized {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .mdc-toolbar--waterfall.mdc-toolbar--fixed.mdc-toolbar--fixed-lastrow-only.mdc-toolbar--flexible-space-minimized {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }
  .mdc-toolbar--waterfall.mdc-toolbar--fixed.mdc-toolbar--fixed-lastrow-only.mdc-toolbar--fixed-at-last-row {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }

.mdc-toolbar-fixed-adjust {
  margin-top: 64px; }
  @media (max-width: 959px) and (max-height: 599px) {
    .mdc-toolbar-fixed-adjust {
      margin-top: 48px; } }
  @media (max-width: 599px) {
    .mdc-toolbar-fixed-adjust {
      margin-top: 56px; } }

.mdc-toolbar__section--shrink-to-fit {
  flex: none; }

@keyframes mdc-ripple-fg-radius-in {
  from {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1); }
  to {
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); } }

@keyframes mdc-ripple-fg-opacity-in {
  from {
    animation-timing-function: linear;
    opacity: 0; }
  to {
    opacity: var(--mdc-ripple-fg-opacity, 0.16); } }

@keyframes mdc-ripple-fg-opacity-out {
  from {
    animation-timing-function: linear;
    opacity: var(--mdc-ripple-fg-opacity, 0.16); }
  to {
    opacity: 0; } }

.mdc-ripple-surface--test-edge-var-bug {
  --mdc-ripple-surface-test-edge-var: 1px solid #000;
  visibility: hidden; }
  .mdc-ripple-surface--test-edge-var-bug::before {
    border: var(--mdc-ripple-surface-test-edge-var); }

/**
 * The css property used for elevation. In most cases this should not be changed. It is exposed
 * as a variable for abstraction / easy use when needing to reference the property directly, for
 * example in a `will-change` rule.
 */
/**
 * The default duration value for elevation transitions.
 */
/**
 * The default easing value for elevation transitions.
 */
/**
 * Applies the correct CSS rules to an element to give it the elevation specified by $z-value.
 * The $z-value must be between 0 and 24.
 * If $color has an alpha channel, it will be ignored and overridden. To increase the opacity of the shadow, use
 * $opacity-boost.
 */
/**
 * Returns a string that can be used as the value for a `transition` property for elevation.
 * Calling this function directly is useful in situations where a component needs to transition
 * more than one property.
 *
 * ```scss
 * .foo {
 *   transition: mdc-elevation-transition-value(), opacity 100ms ease;
 *   will-change: $mdc-elevation-property, opacity;
 * }
 * ```
 */
/* TODO(sgomes): Figure out what to do about desktop font sizes. */
/* TODO(sgomes): Figure out what to do about i18n and i18n font sizes. */
.mdc-button {
  font-family: "Open Sans", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 2.25rem;
  font-weight: 500;
  letter-spacing: 0.04em;
  text-decoration: none;
  text-transform: uppercase;
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  min-width: 64px;
  height: 36px;
  padding: 0 16px;
  border: none;
  outline: none;
  text-align: center;
  user-select: none;
  -webkit-appearance: none;
  overflow: hidden;
  vertical-align: middle;
  border-radius: 2px; }
  .mdc-button::before, .mdc-button::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-button::before {
    transition: opacity 15ms linear; }
  .mdc-button.mdc-ripple-upgraded::after {
    top: 0;
    left: 0;
    transform: scale(0);
    transform-origin: center center; }
  .mdc-button.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    left: var(--mdc-ripple-left, 0); }
  .mdc-button.mdc-ripple-upgraded--foreground-activation::after {
    animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
  .mdc-button.mdc-ripple-upgraded--foreground-deactivation::after {
    animation: 150ms mdc-ripple-fg-opacity-out;
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-button::before, .mdc-button::after {
    top: calc(50% - 100%);
    left: calc(50% - 100%);
    width: 200%;
    height: 200%; }
  .mdc-button.mdc-ripple-upgraded::before {
    top: calc(50% - 100%);
    left: calc(50% - 100%);
    width: 200%;
    height: 200%;
    transform: scale(var(--mdc-ripple-fg-scale, 0)); }
  .mdc-button.mdc-ripple-upgraded--unbounded::before {
    top: var(--mdc-ripple-top, calc(50% - 50%));
    left: var(--mdc-ripple-left, calc(50% - 50%));
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%);
    transform: scale(var(--mdc-ripple-fg-scale, 0)); }
  .mdc-button.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-button:active {
    outline: none; }
  .mdc-button:hover {
    cursor: pointer; }
  .mdc-button::-moz-focus-inner {
    padding: 0;
    border: 0; }
  .mdc-button:disabled {
    background-color: transparent;
    /* @alternate */
    color: rgba(0, 0, 0, 0.38);
    color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38));
    cursor: default;
    pointer-events: none; }
    .mdc-button--theme-dark .mdc-button:disabled,
    .mdc-theme--dark .mdc-button:disabled {
      /* @alternate */
      color: rgba(255, 255, 255, 0.5);
      color: var(--mdc-theme-text-disabled-on-dark, rgba(255, 255, 255, 0.5)); }
  .mdc-button:not(:disabled) {
    background-color: transparent; }
  .mdc-button:not(:disabled) {
    /* @alternate */
    color: #6200ee;
    color: var(--mdc-theme-primary, #6200ee); }
  .mdc-button::before, .mdc-button::after {
    /* @alternate */
    background-color: #6200ee; }
    @supports not (-ms-ime-align: auto) {
      .mdc-button::before, .mdc-button::after {
        background-color: var(--mdc-theme-primary, #6200ee); } }
  .mdc-button:hover::before {
    opacity: 0.04; }
  .mdc-button:not(.mdc-ripple-upgraded):focus::before, .mdc-button.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-button:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-button:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.16; }
  .mdc-button.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: $opacity; }

.mdc-button--raised:disabled,
.mdc-button--unelevated:disabled {
  background-color: rgba(0, 0, 0, 0.12);
  /* @alternate */
  color: rgba(0, 0, 0, 0.38);
  color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38)); }
  .mdc-button--theme-dark .mdc-button--raised:disabled,
  .mdc-theme--dark .mdc-button--raised:disabled, .mdc-button--theme-dark
  .mdc-button--unelevated:disabled,
  .mdc-theme--dark
  .mdc-button--unelevated:disabled {
    background-color: rgba(255, 255, 255, 0.12);
    /* @alternate */
    color: rgba(0, 0, 0, 0.38);
    color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38)); }

.mdc-button--raised:not(:disabled),
.mdc-button--unelevated:not(:disabled) {
  /* @alternate */
  background-color: #6200ee; }
  @supports not (-ms-ime-align: auto) {
    .mdc-button--raised:not(:disabled),
    .mdc-button--unelevated:not(:disabled) {
      background-color: var(--mdc-theme-primary, #6200ee); } }

.mdc-button--raised:not(:disabled),
.mdc-button--unelevated:not(:disabled) {
  /* @alternate */
  color: white;
  color: var(--mdc-theme-text-primary-on-primary, white); }

.mdc-button--raised::before, .mdc-button--raised::after,
.mdc-button--unelevated::before,
.mdc-button--unelevated::after {
  /* @alternate */
  background-color: white; }
  @supports not (-ms-ime-align: auto) {
    .mdc-button--raised::before, .mdc-button--raised::after,
    .mdc-button--unelevated::before,
    .mdc-button--unelevated::after {
      background-color: var(--mdc-theme-text-primary-on-primary, white); } }

.mdc-button--raised:hover::before,
.mdc-button--unelevated:hover::before {
  opacity: 0.08; }

.mdc-button--raised:not(.mdc-ripple-upgraded):focus::before, .mdc-button--raised.mdc-ripple-upgraded--background-focused::before,
.mdc-button--unelevated:not(.mdc-ripple-upgraded):focus::before,
.mdc-button--unelevated.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.24; }

.mdc-button--raised:not(.mdc-ripple-upgraded)::after,
.mdc-button--unelevated:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear; }

.mdc-button--raised:not(.mdc-ripple-upgraded):active::after,
.mdc-button--unelevated:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.32; }

.mdc-button--raised.mdc-ripple-upgraded,
.mdc-button--unelevated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: $opacity; }

.mdc-button--raised {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1); }
  .mdc-button--raised:hover, .mdc-button--raised:focus {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .mdc-button--raised:active {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }
  .mdc-button--raised:disabled {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.mdc-button--stroked {
  border-style: solid;
  padding-right: 14px;
  padding-left: 14px;
  border-width: 2px;
  line-height: 32px; }
  .mdc-button--stroked:disabled {
    /* @alternate */
    border-color: rgba(0, 0, 0, 0.38);
    border-color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38)); }
    .mdc-button--theme-dark .mdc-button--stroked:disabled,
    .mdc-theme--dark .mdc-button--stroked:disabled {
      /* @alternate */
      border-color: rgba(255, 255, 255, 0.5);
      border-color: var(--mdc-theme-text-disabled-on-dark, rgba(255, 255, 255, 0.5)); }
  .mdc-button--stroked.mdc-button--dense {
    line-height: 27px; }
  .mdc-button--stroked.mdc-button--compact {
    padding-right: 6px;
    padding-left: 6px; }
  .mdc-button--stroked:not(:disabled) {
    /* @alternate */
    border-color: #6200ee;
    border-color: var(--mdc-theme-primary, #6200ee); }

.mdc-button--compact {
  padding: 0 8px; }

.mdc-button--dense {
  height: 32px;
  font-size: .8125rem;
  line-height: 32px; }

.mdc-button__icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 8px;
  font-size: 18px;
  line-height: inherit;
  vertical-align: top; }

/**
 * The css property used for elevation. In most cases this should not be changed. It is exposed
 * as a variable for abstraction / easy use when needing to reference the property directly, for
 * example in a `will-change` rule.
 */
/**
 * The default duration value for elevation transitions.
 */
/**
 * The default easing value for elevation transitions.
 */
/**
 * Applies the correct CSS rules to an element to give it the elevation specified by $z-value.
 * The $z-value must be between 0 and 24.
 * If $color has an alpha channel, it will be ignored and overridden. To increase the opacity of the shadow, use
 * $opacity-boost.
 */
/**
 * Returns a string that can be used as the value for a `transition` property for elevation.
 * Calling this function directly is useful in situations where a component needs to transition
 * more than one property.
 *
 * ```scss
 * .foo {
 *   transition: mdc-elevation-transition-value(), opacity 100ms ease;
 *   will-change: $mdc-elevation-property, opacity;
 * }
 * ```
 */
/* TODO(sgomes): Figure out what to do about desktop font sizes. */
/* TODO(sgomes): Figure out what to do about i18n and i18n font sizes. */
.mdc-simple-menu {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  display: none;
  position: absolute;
  box-sizing: border-box;
  min-width: 170px;
  max-width: calc(100vw - 32px);
  max-height: calc(100vh - 32px);
  margin: 0;
  padding: 0;
  transform: scale(1);
  transform-origin: top left;
  border-radius: 2px;
  background-color: white;
  opacity: 0;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: auto;
  will-change: transform, opacity;
  z-index: 4; }
  .mdc-simple-menu--theme-dark,
  .mdc-theme--dark .mdc-simple-menu {
    background-color: #424242; }
  .mdc-simple-menu:focus {
    outline: none; }
  .mdc-simple-menu--animating-open {
    display: inline-block;
    overflow-y: hidden;
    opacity: 0;
    transform: scale(0.8);
    transition: opacity 0.03s linear, transform 0.12s cubic-bezier(0, 0, 0.2, 1); }
    .mdc-simple-menu--animating-open > .mdc-simple-menu__items {
      transform: scale(1.25); }
  .mdc-simple-menu--open {
    display: inline-block;
    transform: scale(1);
    opacity: 1; }
    .mdc-simple-menu--open > .mdc-simple-menu__items {
      transform: scale(1); }
  .mdc-simple-menu--animating-closed {
    display: inline-block;
    overflow-y: hidden;
    opacity: 0;
    transition: opacity 0.075s linear; }
  .mdc-simple-menu__items {
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    will-change: transform;
    transform: scale(1); }
    .mdc-simple-menu__items > .mdc-list-item {
      cursor: pointer; }
    .mdc-simple-menu--animating .mdc-simple-menu__items {
      overflow-y: hidden; }
  [dir="rtl"] .mdc-simple-menu {
    transform-origin: top right; }
  .mdc-simple-menu .mdc-list-group,
  .mdc-simple-menu .mdc-list {
    padding: 8px 0; }
  .mdc-simple-menu .mdc-list-item {
    font-family: "Open Sans", sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 1rem;
    line-height: 1.75rem;
    font-weight: 400;
    letter-spacing: 0.04em;
    text-decoration: inherit;
    text-transform: inherit;
    position: relative;
    outline: none;
    color: inherit;
    text-decoration: none;
    user-select: none; }
    .mdc-simple-menu--theme-dark.mdc-simple-menu .mdc-list-item,
    .mdc-theme--dark .mdc-simple-menu .mdc-list-item {
      color: white; }
  .mdc-simple-menu--theme-dark.mdc-simple-menu .mdc-list-divider,
  .mdc-theme--dark .mdc-simple-menu .mdc-list-divider {
    border-color: rgba(255, 255, 255, 0.12); }
  .mdc-simple-menu .mdc-list-item__graphic {
    color: rgba(0, 0, 0, 0.54); }
    .mdc-simple-menu--theme-dark.mdc-simple-menu .mdc-list-item__graphic,
    .mdc-theme--dark .mdc-simple-menu .mdc-list-item__graphic {
      color: rgba(255, 255, 255, 0.54); }
  .mdc-simple-menu .mdc-list-item[aria-disabled="true"] {
    /* @alternate */
    color: rgba(0, 0, 0, 0.38);
    color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38));
    cursor: default;
    pointer-events: none; }
    .mdc-simple-menu--theme-dark .mdc-simple-menu .mdc-list-item[aria-disabled="true"],
    .mdc-theme--dark .mdc-simple-menu .mdc-list-item[aria-disabled="true"] {
      /* @alternate */
      color: rgba(255, 255, 255, 0.5);
      color: var(--mdc-theme-text-disabled-on-dark, rgba(255, 255, 255, 0.5)); }
    .mdc-simple-menu .mdc-list-item[aria-disabled="true"]:focus::before {
      opacity: 0; }

.mdc-menu-anchor {
  position: relative;
  overflow: visible; }

.mdc-toolbar {
  padding: 0 24px; }
  .mdc-toolbar a.mdc-toolbar__title.vc-logo {
    font-weight: 600;
    color: #FFFFFF; }
    .mdc-toolbar a.mdc-toolbar__title.vc-logo:first-child {
      margin-left: 0; }
  .mdc-toolbar a.mdc-toolbar__title:not(.vc-logo) {
    color: #DEDEDE;
    padding: 16px 0;
    font-weight: 400;
    font-size: 16px; }
    .mdc-toolbar a.mdc-toolbar__title:not(.vc-logo).active, .mdc-toolbar a.mdc-toolbar__title:not(.vc-logo):hover {
      border-bottom: 1px solid;
      color: #FFFFFF; }
  .mdc-toolbar .mdc-toolbar__section.mdc-toolbar__section--align-end .mdc-button {
    background-color: teal; }
  .mdc-toolbar .mdc-toolbar__section .mdc-simple-menu {
    right: 0;
    color: #4A4A4A; }
    .mdc-toolbar .mdc-toolbar__section .mdc-simple-menu .mdc-list {
      margin: 5px 0; }
      .mdc-toolbar .mdc-toolbar__section .mdc-simple-menu .mdc-list .mdc-list-item.vc--no-cursor {
        cursor: default; }
