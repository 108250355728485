body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif; }

@media only screen and (min-width: 993px) {
  .container {
    width: 80%; } }

.vc-helpers__center {
  text-align: center; }

.vc-helpers__right {
  float: right; }

.vc-helpers__right-align {
  text-align: right; }

.vc-image__container {
  line-height: 0; }

.vc-image--responsive {
  width: 100%; }

.vc-button--full-width {
  width: 100%; }
